<template>
  
<div id="app">
     <div class="container">
        <br>
        All managers participating in #elite64NOR leagues 2022/23.<br>
        <br><b>Key numbers from 22/23:</b>
        <li>Average rank: 128636 </li>
        <li>Number of top10ks: {{top10k}} ({{top10kpc}}%)</li>
        <li>Number of top100ks: {{top100k}} ({{top100kpc}}%)</li>
        <br>
        *) E = Elite league: <a href="https://fantasy.premierleague.com/leagues/171269/standings/c" target="_blank">#elite64NOR</a>,
         C = Challenger league: <a href="https://fantasy.premierleague.com/leagues/55986/standings/c" target="_blank">#elite64NOR utfordrere</a>
        <br>**) CyborgScore calculates the managers performance based on the last 5 seasons. Each season back is weighted 10% lower per year, and only ranks up to 100k is considered.
        Numbers shown here are from end of season 22/23. 
            <h2>#elite64NOR Hall of Fame per EOS 22/23</h2>
            <v-table class="table table-striped" :class="[{'table-dark': false}, 'table-bordered']" :data="hofData.managers">
                <thead slot="head" class="thead-dark">
                    <v-th sortKey="name">Manager</v-th>
                    <v-th sortKey="id">ManagerId</v-th>
                    <v-th sortKey="e64League22">e64Nor League (Status) *)</v-th>
                    <v-th sortKey="e64Rank21">e64Nor Rank (League) *)</v-th>
                    <v-th sortKey="cyborgScore21">CyborgScore**)</v-th>
                    <v-th sortKey="overallRank20">Overall FPL Rank</v-th>
                    <v-th sortKey="seasonsPlayed">Seasons Played</v-th>
                    <v-th sortKey="top10ks"># Top10k</v-th>
                    <v-th sortKey="top100ks"># Top100k</v-th>
                </thead>
                <tbody slot="body" slot-scope="{displayData}">
                    <tr v-for="manager in displayData" :key="manager.name">
                    <td>{{manager.name}}</td>
                    <td><a :href="getManagerHistoryLink(manager.id)" target="_blank">{{manager.id}}</a></td>
                    <td>{{manager.e64League22}}  ({{getCurrentLeagueStatusString(manager.e64Status22)}})</td>
                    <td>{{getPastLeagueRankString(manager.e64League21, manager.e64Rank21)}}</td>
                    <td>{{manager.cyborgScore21}}</td>
                    <td>{{manager.overallRank20}}</td>
                    <td>{{manager.seasonsPlayed}}</td>
                    <td>{{manager.top10ks}}</td>
                    <td>{{manager.top100ks}}</td>
                    </tr>
                </tbody>
            </v-table>
            Relegated pre 22/23: Lars Erik Ødegaard, Håkon Aalmen, Tommy Albertsen, Steinar Aarsland, Fabian von Harling, andreas fardal, Espen Kloster, Adrian Moen Hjartnes, Tobias Simonnes, Aasmund Woldsengen, Lars Sjøvold, Kim Are Kold Gabrielsen, Eirik Jokerud, Sindre Mikkelsen Pedersen <br>
            Relegated pre 21/22: Aleksander Svanberg, August Haug Bjerknæs, Eirik Haarr, Eivind Thane, Erik Wikstøl, Espen Bjørkeng, Frederik Okland, Håkon Tollefsen, Jon Arne Gullholm, Magnus Woll Bjartnes, Mikkel Hoff Jensen, Sondre Golden Midtgarden, Tobias Midtlyng
    </div>
</div>
</template>

<script>

import hofData from '../data/HoF2022.json'

export default {
name: 'app',
  components: {},
  data: () => ({
    hofData,
    top10k : '',
    top10kpc : '',
    top100k : '',
    top100kpc : '',
    avgRank: ''
  }),
  mounted: function () {
            this.calculateHofLeagueData();
        },
  methods: {        
            calculateHofLeagueData() {
                let top10k =0, top100k = 0, rankSum = 0;
                let size =  this.hofData.managers.length;
                for (let i = 0; i<size; i++) {
                    let managerOvRank = parseInt(this.hofData.managers[i].overallRank20); 
                    rankSum+=managerOvRank;          
                    if (managerOvRank<10001) {
                        top10k++;
                    }
                    if (managerOvRank<100001) {
                        top100k++;
                    }
                }
                this.top10k = top10k;
                this.top10kpc = Math.round((top10k/size)*100,2);
                this.top100k = top100k;
                this.top100kpc = Math.round((top100k/size)*100,2);
                this.avgRank = Math.round(rankSum/size);

            },
            getManagerHistoryLink(id) {
                return "https://fantasy.premierleague.com/entry/"+id+"/history"
            },
            getCurrentLeagueStatusString(status) {
                let statusString = status;
                if (status=="") {
                    statusString = "Unchanged";
                }
                return statusString;
            },
            getPastLeagueRankString(league, rank) {
                let leagueString = rank +" (" + league +")";
                if (league=="") {
                    leagueString = "NA";
                }
                return leagueString;
            },
        }
    };
</script>

<style>
.thead-dark thead th {
  background: white;
  position: sticky;
  top: 0;
}
</style>
