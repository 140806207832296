<template>
    <div class="container" id="container">

        <div id="status" v-if="fetching" style="margin-top: 10px">   
            {{status}}
            <b-spinner label="Loading..."></b-spinner>
        </div>

         <div id="error" v-if="error&&!fetching">
          Sorry! {{status}} <a href="#" @click="getManagerDataList()"> Click to try again</a>
        </div>

    <div id="top100_league_summary" v-if="!error&&fetchedFirstPage&&!preSeason">
    
    Highest owned players for the E64Nor Managers (all 128 managers in both leagues). Team data updated after GW{{currentGw}} deadline.
    <table id="summarytable">
    <tr>
        <th colspan=2>League template Team</th>
        <td colspan=1 style="font-weight:bold">Most bought players:</td>
    </tr>

    <tr>
        <td colspan=1 style="font-weight:bold">Goalkeepers:</td>
        <td colspan=1 style="font-weight:bold">Midfielders:</td>
        <td colspan=1>{{leagueSummaryData.mostBoughtPlayers[0].name}}: {{getDeltaString(leagueSummaryData.mostBoughtPlayers[0].deltaFromLastGw)}}% (--> {{leagueSummaryData.mostBoughtPlayers[0].ownershipPercentageString}}%)</td>
    </tr>

      <tr>
        <td colspan=1>{{leagueSummaryData.goalkeepers[0].name}} ({{leagueSummaryData.goalkeepers[0].ownershipPercentageString}}%) 
         <span :style="getDeltaColor(leagueSummaryData.goalkeepers[0].deltaFromLastGw)">{{getDeltaString(leagueSummaryData.goalkeepers[0].deltaFromLastGw)}}%</span></td>
        <td colspan=1>{{leagueSummaryData.midfielders[0].name}} ({{leagueSummaryData.midfielders[0].ownershipPercentageString}}%)
         <span :style="getDeltaColor(leagueSummaryData.midfielders[0].deltaFromLastGw)">{{getDeltaString(leagueSummaryData.midfielders[0].deltaFromLastGw)}}%</span></td>
         <td colspan=1>{{leagueSummaryData.mostBoughtPlayers[1].name}}: {{getDeltaString(leagueSummaryData.mostBoughtPlayers[1].deltaFromLastGw)}}% (--> {{leagueSummaryData.mostBoughtPlayers[1].ownershipPercentageString}}%)</td>
        
    </tr>
    <tr>
        <td colspan=1>{{leagueSummaryData.goalkeepers[1].name}} ({{leagueSummaryData.goalkeepers[1].ownershipPercentageString}}%)
         <span :style="getDeltaColor(leagueSummaryData.goalkeepers[1].deltaFromLastGw)">{{getDeltaString(leagueSummaryData.goalkeepers[1].deltaFromLastGw)}}%</span></td>
        <td colspan=1>{{leagueSummaryData.midfielders[1].name}} ({{leagueSummaryData.midfielders[1].ownershipPercentageString}}%)
            <span :style="getDeltaColor(leagueSummaryData.midfielders[1].deltaFromLastGw)">{{getDeltaString(leagueSummaryData.midfielders[1].deltaFromLastGw)}}%</span></td>
        <td colspan=1>{{leagueSummaryData.mostBoughtPlayers[2].name}}: {{getDeltaString(leagueSummaryData.mostBoughtPlayers[2].deltaFromLastGw)}}% (--> {{leagueSummaryData.mostBoughtPlayers[2].ownershipPercentageString}}%)</td>
        
    </tr>
    <tr>
        <td colspan=1>{{leagueSummaryData.goalkeepers[2].name}} ({{leagueSummaryData.goalkeepers[2].ownershipPercentageString}}%)
            <span :style="getDeltaColor(leagueSummaryData.goalkeepers[2].deltaFromLastGw)">{{getDeltaString(leagueSummaryData.goalkeepers[2].deltaFromLastGw)}}%</span></td>
        <td colspan=1>{{leagueSummaryData.midfielders[2].name}} ({{leagueSummaryData.midfielders[2].ownershipPercentageString}}%) 
            <span :style="getDeltaColor(leagueSummaryData.midfielders[2].deltaFromLastGw)">{{getDeltaString(leagueSummaryData.midfielders[2].deltaFromLastGw)}}%</span></td>
        <td colspan=1 style="font-weight:bold">Most sold players:</td>    
        
    </tr>
    <tr>
        <td colspan=1>{{leagueSummaryData.goalkeepers[3].name}} ({{leagueSummaryData.goalkeepers[3].ownershipPercentageString}}%)
            <span :style="getDeltaColor(leagueSummaryData.goalkeepers[3].deltaFromLastGw)">{{getDeltaString(leagueSummaryData.goalkeepers[3].deltaFromLastGw)}}%</span></td>
        <td colspan=1>{{leagueSummaryData.midfielders[3].name}} ({{leagueSummaryData.midfielders[3].ownershipPercentageString}}%)
            <span :style="getDeltaColor(leagueSummaryData.midfielders[3].deltaFromLastGw)">{{getDeltaString(leagueSummaryData.midfielders[3].deltaFromLastGw)}}%</span></td>
            <td colspan=1>{{leagueSummaryData.mostSoldPlayers[0].name}}: {{getDeltaString(leagueSummaryData.mostSoldPlayers[0].deltaFromLastGw)}}% (--> {{leagueSummaryData.mostSoldPlayers[0].ownershipPercentageString}}%)</td>
    </tr>
    <tr>
        <td colspan=1 style="font-weight:bold">Defenders:</td>
        <td colspan=1>{{leagueSummaryData.midfielders[4].name}} ({{leagueSummaryData.midfielders[4].ownershipPercentageString}}%)
            <span :style="getDeltaColor(leagueSummaryData.midfielders[4].deltaFromLastGw)">{{getDeltaString(leagueSummaryData.midfielders[4].deltaFromLastGw)}}%</span></td>
        <td colspan=1>{{leagueSummaryData.mostSoldPlayers[1].name}}: {{getDeltaString(leagueSummaryData.mostSoldPlayers[1].deltaFromLastGw)}}% (--> {{leagueSummaryData.mostSoldPlayers[1].ownershipPercentageString}}%)</td>
     
            
    </tr>
    <tr>
        <td colspan=1>{{leagueSummaryData.defenders[0].name}} ({{leagueSummaryData.defenders[0].ownershipPercentageString}}%)
            <span :style="getDeltaColor(leagueSummaryData.defenders[0].deltaFromLastGw)">{{getDeltaString(leagueSummaryData.defenders[0].deltaFromLastGw)}}%</span></td>
        <td colspan=1>{{leagueSummaryData.midfielders[5].name}} ({{leagueSummaryData.midfielders[5].ownershipPercentageString}}%)
            <span :style="getDeltaColor(leagueSummaryData.midfielders[5].deltaFromLastGw)">{{getDeltaString(leagueSummaryData.midfielders[5].deltaFromLastGw)}}%</span></td>
        <td colspan=1>{{leagueSummaryData.mostSoldPlayers[2].name}}: {{getDeltaString(leagueSummaryData.mostSoldPlayers[2].deltaFromLastGw)}}% (--> {{leagueSummaryData.mostSoldPlayers[2].ownershipPercentageString}}%)</td>
      
    </tr>
    <tr>
        <td colspan=1>{{leagueSummaryData.defenders[1].name}} ({{leagueSummaryData.defenders[1].ownershipPercentageString}}%)
            <span :style="getDeltaColor(leagueSummaryData.defenders[1].deltaFromLastGw)">{{getDeltaString(leagueSummaryData.defenders[1].deltaFromLastGw)}}%</span></td>
        <td colspan=1>{{leagueSummaryData.midfielders[6].name}} ({{leagueSummaryData.midfielders[6].ownershipPercentageString}}%)
            <span :style="getDeltaColor(leagueSummaryData.midfielders[6].deltaFromLastGw)">{{getDeltaString(leagueSummaryData.midfielders[6].deltaFromLastGw)}}%</span></td>       
        <th colspan=1>Chip Usage</th>
    </tr>
    <tr>
        <td colspan=1>{{leagueSummaryData.defenders[2].name}} ({{leagueSummaryData.defenders[2].ownershipPercentageString}}%)
            <span :style="getDeltaColor(leagueSummaryData.defenders[2].deltaFromLastGw)">{{getDeltaString(leagueSummaryData.defenders[2].deltaFromLastGw)}}%</span></td>
        <td colspan=1 style="font-weight:bold">Attackers:</td>
        
        <td>WC1: {{leagueSummaryData.chipUsageList[0].percentage}}% ({{getDeltaString(leagueSummaryData.chipUsageList[0].deltaFromLast)}}%)</td>
        
    </tr>
    <tr>
        <td colspan=1>{{leagueSummaryData.defenders[3].name}} ({{leagueSummaryData.defenders[3].ownershipPercentageString}}%)
            <span :style="getDeltaColor(leagueSummaryData.defenders[3].deltaFromLastGw)">{{getDeltaString(leagueSummaryData.defenders[3].deltaFromLastGw)}}%</span></td>
        <td colspan=1>{{leagueSummaryData.attackers[0].name}} ({{leagueSummaryData.attackers[0].ownershipPercentageString}}%)
            <span :style="getDeltaColor(leagueSummaryData.attackers[0].deltaFromLastGw)">{{getDeltaString(leagueSummaryData.attackers[0].deltaFromLastGw)}}%</span></td>
            <td>BB: {{leagueSummaryData.chipUsageList[1].percentage}}% ({{getDeltaString(leagueSummaryData.chipUsageList[1].deltaFromLast)}}%)</td>
    </tr>
    <tr>
        <td colspan=1>{{leagueSummaryData.defenders[4].name}} ({{leagueSummaryData.defenders[4].ownershipPercentageString}}%)
            <span :style="getDeltaColor(leagueSummaryData.defenders[4].deltaFromLastGw)">{{getDeltaString(leagueSummaryData.defenders[4].deltaFromLastGw)}}%</span></td>
        <td colspan=1>{{leagueSummaryData.attackers[1].name}} ({{leagueSummaryData.attackers[1].ownershipPercentageString}}%)
            <span :style="getDeltaColor(leagueSummaryData.attackers[1].deltaFromLastGw)">{{getDeltaString(leagueSummaryData.attackers[1].deltaFromLastGw)}}%</span></td>
            <td>TC: {{leagueSummaryData.chipUsageList[3].percentage}}% ({{getDeltaString(leagueSummaryData.chipUsageList[3].deltaFromLast)}}%)</td>
    </tr>
    <tr>
        <td colspan=1>{{leagueSummaryData.defenders[5].name}} ({{leagueSummaryData.defenders[5].ownershipPercentageString}}%)
            <span :style="getDeltaColor(leagueSummaryData.defenders[5].deltaFromLastGw)">{{getDeltaString(leagueSummaryData.defenders[5].deltaFromLastGw)}}%</span></td>
        <td colspan=1>{{leagueSummaryData.attackers[2].name}} ({{leagueSummaryData.attackers[2].ownershipPercentageString}}%)
            <span :style="getDeltaColor(leagueSummaryData.attackers[2].deltaFromLastGw)">{{getDeltaString(leagueSummaryData.attackers[2].deltaFromLastGw)}}%</span></td>
            <td>FH: {{leagueSummaryData.chipUsageList[2].percentage}}% ({{getDeltaString(leagueSummaryData.chipUsageList[2].deltaFromLast)}}%)</td>
    </tr>
    <tr>
        <td colspan=1>{{leagueSummaryData.defenders[6].name}} ({{leagueSummaryData.defenders[6].ownershipPercentageString}}%)
            <span :style="getDeltaColor(leagueSummaryData.defenders[6].deltaFromLastGw)">{{getDeltaString(leagueSummaryData.defenders[6].deltaFromLastGw)}}%</span></td>
        <td colspan=1>{{leagueSummaryData.attackers[3].name}} ({{leagueSummaryData.attackers[3].ownershipPercentageString}}%)
            <span :style="getDeltaColor(leagueSummaryData.attackers[3].deltaFromLastGw)">{{getDeltaString(leagueSummaryData.attackers[3].deltaFromLastGw)}}%</span></td>
            <td>WC2: {{leagueSummaryData.chipUsageList[4].percentage}}% ({{getDeltaString(leagueSummaryData.chipUsageList[4].deltaFromLast)}}%)</td>
    </tr>

    </table> 
    </div>

        <div id= "top100list_preseason" v-if="!error&&fetchedFirstPage&&preSeason">
            <br>
            Top CyborgRanks in FPL. Showing data for the preseason, season 2023/24 has not started yet.
            
             <a href="#docview"> Documentation here.</a>
            <div id="managertable">
            <v-table class="table table-striped" :class="[{'table-dark': false}, 'table-bordered']" :data="managerDataList">
                <thead slot="head" class="thead-dark">
                <v-th sortKey="cyborgRank" defaultSort="asc">CyborgRank</v-th>
                <v-th sortKey="managerName">Manager</v-th>
                <v-th sortKey="teamName">Team Name</v-th>
                <v-th sortKey="eliteNorType">E64Nor League</v-th>
                <v-th sortKey="cyborgScore">CyborgScore</v-th>
                <v-th sortKey="topTenKs"># Top10Ks</v-th>
                <v-th sortKey="topHundredKs"># Top100Ks</v-th>
                <v-th sortKey="currentSeason">Updated for season</v-th>
                </thead>
                <tbody id="managerList" v-if="!error&&fetchedFirstPage" slot="body" slot-scope="{displayData}">
                <tr v-for="managerData in displayData" :key="managerData.managerName+managerData.managerId">
                    <td>{{managerData.cyborgRank}}
                        <span :style="getValueDeltaRankColor(managerData.cyborgRank, managerData.lastCyborgRank)">
                            ({{getValueDeltaRank(managerData.cyborgRank, managerData.lastCyborgRank)}})</span>
                    </td>
                    <td><a :href="getManagerHistoryLink(managerData.managerId)" target="_blank">{{managerData.managerName}}</a></td>  
                    <td>{{managerData.teamName}}</td>
                    <td>{{managerData.eliteNorType}}</td>
                    <td>{{managerData.cyborgScore}} 
                        <span :style="getValueDeltaScoreColor(managerData.cyborgScore, managerData.lastCyborgScore)">
                            ({{getValueDeltaScore(managerData.cyborgScore, managerData.lastCyborgScore)}})</span>
                    </td>
                    <td>{{managerData.topTenKs}}</td>
                    <td>{{managerData.topHundredKs}}</td>
                    <td>{{managerData.currentSeason}}</td>
                    </tr>
                </tbody>
            </v-table>
            </div>
        </div> 

    <div id="seaason_started" v-if=fetchedFirstPage&&!preSeason>
        Ranks and scores are updated after end of GW{{lastFinishedGw}}.
            <a href="#docview"> Documentation here.</a>

       <div id="select_league" style="margin-top:20px;margin-bottom:20px" @change="updateManagerDataList($event)">
           Select a league to view data: 
            <b-form-select v-model="selectedLeagueId" size="lg">
                <option v-for="league in leagueOptions" v-bind:value="league.leagueId" :key="league.leagueId">
                    <b>{{league.leagueName}}</b>
                </option>
            </b-form-select>
        </div>

        <div id ="toggle_button_league_views" v-if="fetchedFirstPage">
            Select view: 
            <toggle-button @change="toggleSeasonData()" :width="80" :height="30"
             :color="{checked: '#074680', unchecked: '#000407'}"
               :labels="{checked: 'Historic', unchecked: 'Season'}"/>
        </div>
        <div id= "league_season" v-if="fetchedFirstPage&&seasonData">
            <v-table class="table table-striped" :class="[{'table-dark': false}, 'table-bordered']" :data="managerDataList">
                <thead slot="head" class="thead-dark">
                <v-th sortKey="overallRank" defaultSort="asc">Overall Rank</v-th>
                <v-th sortKey="eliteNorType" v-if="showAll">E64Nor League</v-th>
                <v-th sortKey="managerName">Manager</v-th>
                <v-th sortKey="teamnName">Team</v-th>            
                <v-th sortKey="overallPoints">Points</v-th>
                <v-th sortKey="cyborgScore">CyborgScore</v-th>
                <v-th sortKey="aggressivityScore">AggroScore</v-th>
                <v-th sortKey="templateScore">TemplateScore (top1000)</v-th>
                <v-th sortKey="overallTemplateScore">Overall TemplateScore</v-th>
                <v-th sortKey="totalPointsBenched">Total Benchpoints</v-th>    
                <v-th sortKey="totalTransfers">Transfers</v-th>
                <v-th sortKey="totalHits">Hits</v-th>
                <v-th sortKey="teamValue">Team Value</v-th>  
                <v-th sortKey="bestGwRank">Best GW Rank</v-th>
                <v-th sortKey="wcFirst">WC1</v-th>
                <v-th sortKey="wcSecond">WC2</v-th>
                <v-th sortKey="fh">FH</v-th>
                <v-th sortKey="bb">BB</v-th>
                <v-th sortKey="tc">TC</v-th>
                </thead>
                <tbody slot="body" slot-scope="{displayData}">
                <tr v-for="managerData in displayData" :key="managerData.managerName+managerData.managerId">
                    <td>{{managerData.overallRank}}
                        <span :style="getValueDeltaRankColor(managerData.overallRank, managerData.lastOverallRank)">
                            ({{getValueDeltaRank(managerData.overallRank, managerData.lastOverallRank)}})</span></td>
                    <td v-if="showAll">{{managerData.eliteNorType}}</td>
                    <td><a :href="getManagerHistoryLink(managerData.managerId)" target="_blank">{{managerData.managerName}}</a></td>
                    <td>{{managerData.teamName}}</td>                
                    <td>{{managerData.overallPoints}}</td>
                    <td>{{managerData.cyborgScore}}
                    <span :style="getValueDeltaScoreColor(managerData.cyborgScore, managerData.lastCyborgScore)">
                            ({{getValueDeltaScore(managerData.cyborgScore, managerData.lastCyborgScore)}})</span></td>
                    <td>{{managerData.aggressivityScore}}</td>
                    <td>{{managerData.templateScore}}</td>
                    <td>{{managerData.overallTemplateScore}}</td>
                    <td>{{managerData.totalPointsBenched}}</td>   
                    <td>{{managerData.totalTransfers}}</td>
                    <td>{{managerData.totalHits}}</td>
                    <td>{{managerData.teamValue}}</td>  
                    <td>{{managerData.bestGwRank}}</td>
                    <td>{{managerData.wcFirst}}</td> 
                    <td>{{managerData.wcSecond}}</td> 
                    <td>{{managerData.fh}}</td> 
                    <td>{{managerData.bb}}</td> 
                    <td>{{managerData.tc}}</td> 
                    </tr>
                </tbody>
            </v-table>
        </div>
        <div id= "league_historic" v-if="fetchedFirstPage&&!seasonData">
            <v-table class="table table-striped" :class="[{'table-dark': false}, 'table-bordered']" :data="managerDataList">
                <thead slot="head" class="thead-dark">
                <v-th sortKey="cyborgRank" defaultSort="asc">Cyborg Rank</v-th>
                <v-th sortKey="eliteNorType" v-if="showAll">E64Nor League</v-th>
                <v-th sortKey="managerName">Manager</v-th>
                <v-th sortKey="managerId">ManagerId</v-th>
                <v-th sortKey="cyborgScore">CyborgScore</v-th>
                <v-th sortKey="seasonsPlayed">Seasons Played</v-th>
                <v-th sortKey="averageRank">Average Rank</v-th>
                <v-th sortKey="bestRank">Best Rank</v-th>
                <v-th sortKey="topTenKs"># Top10Ks</v-th>
                <v-th sortKey="topHundredKs"># Top100Ks</v-th>
                <v-th sortKey="favoriteTeam">Favourite Team</v-th>
                </thead>
                <tbody slot="body" slot-scope="{displayData}">
                <tr v-for="managerData in displayData" :key="managerData.managerName+managerData.managerId">
                    <td>{{managerData.cyborgRank}}
                        <span :style="getValueDeltaRankColor(managerData.cyborgRank, managerData.lastCyborgRank)">
                            ({{getValueDeltaRank(managerData.cyborgRank, managerData.lastCyborgRank)}})</span></td>
                    <td v-if="showAll">{{managerData.eliteNorType}}</td>
                    <td><a :href="getManagerHistoryLink(managerData.managerId)" target="_blank">{{managerData.managerName}}</a></td>
                    <td>{{managerData.managerId}}</td>
                    <td>{{managerData.cyborgScore}}
                        <span :style="getValueDeltaScoreColor(managerData.cyborgScore, managerData.lastCyborgScore)">
                            ({{getValueDeltaScore(managerData.cyborgScore, managerData.lastCyborgScore)}})</span></td>
                    <td>{{managerData.seasonsPlayed}}</td>
                    <td>{{managerData.averageRank}}</td>
                    <td>{{managerData.bestRank}}</td>
                    <td>{{managerData.topTenKs}}</td>
                    <td>{{managerData.topHundredKs}}</td>
                    <td>{{managerData.favoriteTeam}}</td>
                    </tr>
                </tbody>
            </v-table>
        </div>
    </div>
           
            <div id = "docview">
            Documentation: (<a href="#top100list">Go back to top</a>)
            <ul>
            <li><b>CyborgScore</b> is calculated based on the current season and the 10 past seasons. Only top 2% of total players ranks are counted, and most recent seasons are weighted higher than older seasons</li>
            <li>The current season is weighted according to the number of GWs played. E.g. after GW18 the season is weighted 50%</li>
            <li><b>TemplateScore</b> takes the ownership of each player in the manager team, compares it with the top1000 managers, and calculates average ownership for the starting 11</li>
            <li>The score would be from 0 if the players a manager have are owned by no other managers in the selection, to 100 if all managers had the exact same team</li>
            <li>The TemplateScore does not only look at the current GW, but is the average templateness compared with top managers for each GW played</li>
            <li>Due to database and calculation constraints the site can only show TemplateScore for the top100 managers at the start of the season per now</li>
            <li><b>AggroScore</b> is a measurement on how early the manager makes transfers each GW, how many transfers are made and also how early the WCs are played</li>
            <li>A manager taking e.g 2 or more hits on the first day each GW and also playing GW on first oppertunity, would have 100%. A manager making 0 transfers and not using WC would have 0%</li>
            </ul>
        </div>
    </div>

</template>

<script>    
    import axios from 'axios'

export default {
  name: 'app',
  components: {},
  data() { 
        return {
            status : '',
            error : false,
            fetching: false,
            fetchedFirstPage : false,
            currentGw : '',
            lastFinishedGw : '',
            managerDataList : [],
            leagueSummaryData : [],
            showFaq: false,
            offset: 0,
            preSeason: false,
            seasonData: true,
            selectedLeagueId : 'All',
            showAll: true,
            leagueOptions: [
                {leagueName: '#elite64NOR All managers', leagueId: 'All'},  
                {leagueName: '#elite64NOR Main', leagueId: 'E'},
                {leagueName: '#elite64NOR Challenger', leagueId: 'C'}
                  
            ],
            }
        },
        mounted: function () {
            this.getManagerDataList('All');
        },
        methods: {
            getManagerDataList: function (leagueId) {      
                var fpc_be_url;
                if (leagueId==='E') {
                    fpc_be_url = 'https://fplcyborg-be.herokuapp.com/getManagerDbData/?league=E64Nor&e64norLeagueType=E'
                    this.showAll = false;
                } else if  (leagueId==='C') {
                    fpc_be_url = 'https://fplcyborg-be.herokuapp.com/getManagerDbData/?league=E64Nor&e64norLeagueType=C'
                    this.showAll = false;
                } else {
                    fpc_be_url = 'https://fplcyborg-be.herokuapp.com/getManagerDbData/?league=E64Nor';
                    this.showAll = true;
                }
                this.fetching = true;
                this.fetchedFirstPage = false; 
                this.status = 'Getting data from FPL';

                axios.get(fpc_be_url)
                    .then(top100Response => {
                        if (top100Response.data.currentGw == 0) {
                            this.preSeason = true;
                        }
                        this.managerDataList = top100Response.data.managerDataList;
                        this.leagueSummaryData = top100Response.data.leagueSummaryData;
                        this.currentGw = top100Response.data.currentGw;
                        this.lastFinishedGw = top100Response.data.lastFinishedGw;
                        this.status = 'Data fetched ok';
                        this.fetching = false;
                        this.fetchedFirstPage = true;
                    })
                    .catch(error => {
                        console.log(error);
                        this.fetching = false;
                        this.fetchedFirstPage = true;
                        this.error = true;
                        this.status = 'Error fetching top100 data';
                    });
            },
            updateManagerDataList: function (event) {
                var leagueId = event.target.value;
                if (leagueId == null) {
                    leagueId = this.selectedLeagueId;
                } else {
                    this.getManagerDataList(event.target.value);
                }
            },
            getManagerHistoryLink(id) {
                return "https://fantasy.premierleague.com/entry/"+id+"/history"
            },
            getDeltaString(delta) {
                var deltaString = (Math.round(delta* 100) / 100).toFixed(1)
                if (delta<0) {
               return  deltaString
            }   else {
                return '+' + deltaString; 
               }
            },
            getDeltaColor(delta) {
              if (delta<0) {
                return "color:red";
              } else if (delta >0) {
                return "color:green";
              } else {
                return "color:black"
              }
            },
            getValueDeltaRankColor(newValue, lastValue) {
            var delta = this.getValueDeltaRank(newValue, lastValue);
              if (delta>0) {
                return "color:red";
              } else if (delta <0) {
                return "color:green";
              } else {
                return "color:black"
              }
            },
            getValueDeltaRank(newValue, lastValue) {
              if (newValue !== undefined || lastValue !== undefined) {
              var delta = newValue - lastValue;
              if (delta > 0) {
                delta = '+' + delta;
              } else if (delta == 0) {
                delta = '-';
              }
              return delta;
              } else { 
                return '-';
              }
            },
            getValueDeltaScoreColor(newValue, lastValue) {
              var value = this.getValueDeltaScore(newValue, lastValue);
              return this.getDeltaColor(value);
            },
            getValueDeltaScore(newValue, lastValue) {
                if (newValue !== undefined || lastValue !== undefined) {
                var delta = newValue - lastValue;
                delta = Math.round(delta*100)/100;
                if (delta>0) {
                    delta = '+' + delta;
                } else if (delta == 0) {
                    delta = '-';
                }
                return delta;
            }else { 
                return '-';
              }
            },
            toggleSeasonData() {
                this.seasonData = !this.seasonData;
            },
            toggleFaq() {
                this.showFaq = !this.showFaq;
            }
    }
} 
</script>

<style>
    [v-cloak] {
        display: none;
    }
    #container {
        margin-top:20px;
        display: flex;
        flex-direction: column;
        max-width: 1070px;
    }
    #summarytable th, #summarytable td{
    padding:0.2em;
    border: 1px inset;
    text-align: center;
    }
    #summarytable th{
    background-color:#1e1e1f;
    color: #f9f9fc;
    font-weight: bold;
    }
    
    #faqview{
    font-size: 12px;
    }
    #pagination{
        display: flex;
        justify-content: center;
        align-items: center;
        border: solid 1px;
    }
    #pagination button{
        border:none;
        margin: 1em;
        background-color: lightgray;
        border-radius:8px;
        position: inherit;
    }

</style>