const data = {
    '1':['Jøran Solli','Shady Whims','2647','1820'],
    '2':['Finn Olav Haga','Godlia Åresalgslag','2643','2154'],
    '3':['Christoffer Olsen','Vinodrama','2629','4067'],
    '4':['Frank Pedersen','Worthless Men','2615','7187'],
    '5':['Tom Eklund-Aarlia','VeniVidiVici','2612','8178'],
    '6':['Sigurd Holen','Banana Ljubljana','2607','9796'],
    '7':['Ola Svanheld','Qilin FC','2602','11713'],
    '8':['Endre Berg Leiren','Løsningsforslaget','2593','16002'],
    '9':['Jørgen Haugli Johansen','FC Full Sprut','2591','17316'],
    '10':['Jorgen Perminow','Punisher FC','2588','19039'],
    '11':['Christian Hjorth','Paradise Lost','2587','19775'],
    '12':['Mathias Madslien','mathias','2586','20165'],
    '13':['Petter Andreassen','Onomah Son','2585','21149'],
    '14':['Gøran Birkeland','Danny Hamstrings','2585','21353'],
    '15':['Alfred Askvig','Hakuna Mateta','2571','32516'],
    '16':['Robert Leithe','Rustipikk','2569','34438'],
    '17':['Remy Snilstveit','Reggy’s XI','2569','34902'],
    '18':['Emil Brotangen','Pitbulls','2567','36962'],
    '19':['Jarle holgersen','General forsamling','2564','40059'],
    '20':['Trond-Vegard Kristiansen','2 > 1','2558','47473'],
    '21':['Jonas Rocco Hansen','Zinédine Butane','2556','50488'],
    '22':['Markus Løland','Boondock Harriers','2555','51729'],
    '23':['Jonas Øvestad','Lovalova','2554','52391'],
    '24':['Rune Bjørnsen','Leveransefabrikken','2554','52795'],
    '25':['Frode Rekve','The Gluecifers','2554','52846'],
    '26':['Tor Arne Momrak','Fyresdal','2551','57136'],
    '27':['Svein Inge Østebø','BadaBing','2550','58275'],
    '28':['Karl Felix Krafft','Penišić','2547','62758'],
    '29':['Bjørnar Jakobsen','Arrizabalagarba','2544','68406'],
    '30':['Lars Henrik Haugeli','Larsenal FC','2541','72558'],
    '31':['Felix Felixsen','Backups','2539','77076'],
    '32':['orjan knutsen','SirKnutsen','2539','77152'],
    '33':['Johan Løken','Rimelig tvil','2532','91553'],
    '34':['Henning Stien','Gabbes gjeng','2530','94652'],
    '35':['Henrik Haukom','FC Dampveivals','2529','96411'],
    '36':['Hallstein Brøtan','Halfstoned Halfballs','2529','97405'],
    '37':['Jan Ødegård','Botman and Robin','2529','97405'],
    '38':['Ola Kalvø Vattøy','BoomXhakaSaka','2526','104098'],
    '39':['Magnus Ask Mikkelsen','FC Fishparty','2523','109577'],
    '40':['Tormod Herland','Kveitebolle','2522','112819'],
    '41':['Olve Engelsen','Conscindo','2519','118886'],
    '42':['Ole Kevin Rodberg','Snik United','2516','127828'],
    '43':['Espen Strom','Motstrøm','2514','133139'],
    '44':['Even Egeberg','Romedal City','2509','146166'],
    '45':['Aleksander Nordahl','Axanor FC','2503','163442'],
    '46':['Kristoffer Eikrem','123456789','2502','165001'],
    '47':['endre refvik','Darwinism','2501','167535'],
    '48':['Besmir K','11 venstrebeinte','2501','168798'],
    '49':['Anders Røstad','Sanatoriekameratene','2499','174649'],
    '50':['Kjartan Hjermann Døhlie','Kjart1 FC','2493','193535'],
    '51':['Fredrik Kolsrud','Mig','2492','196338'],
    '52':['Jon Braaten','Rock Bottom','2474','262114'],
    '53':['Anders Kirchoff','Bricklayers','2461','317374'],
    '54':['Espen Johnsrud','Team Johnsrud','2460','321204'],
    '55':['Håvard Ree Hanson','Canaries','2457','333292'],
    '56':['Kristian Bokn','Bokn Juniors','2454','345728'],
    '57':['Andreas Woll','Kefir','2453','352518'],
    '58':['Tobias Nymo Melseth','Kjøret går','2429','476140'],
    '59':['Øyvind Mundal','Munchester FC','2428','480696'],
    '60':['Kent Ims Borsheim','The Borsheim','2426','493103'],
    '61':['Richard Aaserud','!','2413','570970'],
    '62':['Andreas Neegaard','Lord of the Ings','2407','610017'],
    '63':['Magnus Bryn','FC Maglinators','2403','636715'],
    '64':['bjorn andenas','Bislet Barfighters','2376','829201'],
}

export default data;