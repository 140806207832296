const data = {
    '1':['Stephan Haugsrud','ChroniclesOfRuddock','2596', '150'],
    '2':['Jesper Øiestad','Reisenissene','2591', '194'],
    '3':['Frode Molnes','Sunshine Coast FC','2555', '1043'],
    '4':['David Faukner Bendiksen','SlankBakfrasOrkester','2555', '1052'],
    '5':['Christian Hunstad Flotvik','Shaw Mee Tah Mané','2555', '1057'],
    '6':['Torkel Wahl-Olsen','De Wahlistiske','2552', '1177'],
    '7':['Jon Thomsen','Bleeding Doll','2543', '1684'],
    '8':['Bjarne Martinussen','1860 Solås','2542', '1740'],
    '9':['Jørgen Stenseth','Bromstad Bonkers','2540', '1907'],
    '10':['arne barsnes','WesMantooth','2538', '2025'],
    '11':['Knut Masdal','Knutby90','2534', '2367'],
    '12':['Markus Løland','Boondock Harriers','2528', '2972'],
    '13':['Kenneth Larsen','LaSSie Heath L&YR FC','2507', '6067'],
    '14':['Arild Formo','MrFormo','2507', '6221'],
    '15':['Anders Lium','AFC Helvete','2501', '7345'],
    '16':['Jon Braaten','Rock Bottom','2501', '7519'],
    '17':['Daniel Brenn','Brenn Mee','2499', '7909'],
    '18':['Benjamin Våge Nilsen','Benni´s','2498', '8081'],
    '19':['Richard Gumpen Hasselberg','Cornelis Elander','2496', '8683'],
    '20':['Ola Svanheld','Qilin FC','2494', '9202'],
    '21':['Sindre Mikkelsen Pedersen','Klopptimistene','2492', '9892'],
    '22':['Morten Offerdal','Merseyside FC','2486', '11719'],
    '23':['Pål Jonas Almås','Trent van Robertson','2485', '12075'],
    '24':['Ole Martin Stabbetorp','Death Cab for Cutie','2465', '21221'],
    '25':['Jonas BerghJohnsen','Sexes’ disipler','2462', '22931'],
    '26':['Kent Ims Borsheim','The Borsheims','2460', '24171'],
    '27':['Tore Kalleberg','Stang Inn','2458', '25182'],
    '28':['Eivind Almhjell','Sifl','2454', '27848'],
    '29':['Jarle Kjær Bremseth','Borg IF','2453', '28358'],
    '30':['Runar Andersen','Revenge vol.23','2450', '30627'],
    '31':['Pål Schjerven','Schjervinhos','2450', '30913'],
    '32':['Eirik Jokerud','Krødern Red Bulls','2449', '31617'],
    '33':['Stian Nilsen Nornes','Supphamar Patriots','2446', '33943'],
    '34':['Tom Eklund-Aarlia','VeniVidiVici','2441', '37857'],
    '35':['Robin Sulen','RyanGiggsNiece','2438', '40429'],
    '36':['Magnus Tjelle','Rettferdighet','2438', '40699'],
    '37':['Ronny Greipsland','Bing Sporting Club','2428', '50239'],
    '38':['Frank Mundal','Losonjo FK','2427', '51289'],
    '39':['Håvard Ree Hanson','Canaries','2425', '53672'],
    '40':['Øyvind Godø','Pepperoni-effekten','2425', '53845'],
    '41':['Christian Gjertveit','Langemann lurer alle','2425', '53930'],
    '42':['Jorgen Perminow','FC Punisher','2421', '58494'],
    '43':['Øyvind Larsen','Team Sizo','2420', '59452'],
    '44':['Mats Oftedal','Oftis United','2419', '60719'],
    '45':['Mats Sigstad','Mats United','2415', '65317'],
    '46':['Aleksander Våge Nilsen','Ei Saa Peittää','2413', '68621'],
    '47':['Øyvind Nordengen','Fassa Bortolo','2411', '70661'],
    '48':['Felix Stephensen','Backups','2405', '79417'],
    '49':['andreas heiberg','Team Hebb','2391', '101747'],
    '50':['Fredrik Kolsrud','Godset 2021','2374', '135365'],
    '51':['Even Egeberg','Romedal City','2370', '143479'],
    '52':['Magnar Nordtun','FC Magnars','2369', '146396'],
    '53':['Jøran Solli','Shady Whims','2366', '153888'],
    '54':['Jarle holgersen','19 times','2364', '157250'],
    '55':['bjorn andenas','Bislet Barfighters','2359', '169975'],
    '56':['Ole Kevin Rodberg','Aubameyang Schøneman','2354', '182643'],
    '57':['Magnus Woll Bjartnes','Norwegian Greenwood','2345', '206940'],
    '58':['Anders Kirchoff','Bricklayers','2342', '217309'],
    '59':['Espen Bjørkeng','Lokomotivet','2332', '246132'],
    '60':['Kristian Bokn','Bokn Juniors','2331', '249094'],
    '61':['Jørgen Haugli Johansen','FC Full Sprut','2325', '269586'],
    '62':['Sindre Askeland','Cinico','2317', '297343'],
    '63':['Tommy Albertsen','Next year....','2228', '732881'],
    '64':['Andreas Woll','Kefir','1957', '3035074']
}

export default data;