const data = {
    '1':['Jarle holgersen','','','623'],
    '2':['rob ins','','','629'],
    '3':['Håvard Ree Hanson','','','1452'],
    '4':['Felix Stephensen','','','2521'],
    '5':['Christian Gjertveit','','','3465'],
    '6':['Jørgen Haugli Johansen','','','4005'],
    '7':['Stian Nilsen Nornes','','','4518'],
    '8':['Arild Formo','','','7556'],
    '9':['Magnus Tjelle','','','9293'],
    '10':['Ola Svanheld','','','10117'],
    '11':['Daniel Brenn','','','10631'],
    '12':['Sindre Mikkelsen Pedersen','','','10769'],
    '13':['Jørgen Stenseth','','','12195'],
    '14':['Andreas Woll','','','14405'],
    '15':['Christian Aasen','','','16353'],
    '16':['Trond Vegard Kristiansen','','','16366'],
    '17':['Håkon Aalmen','','','17257'],
    '18':['Eirik Nordengen','','','18225'],
    '19':['Besmir Kajolli','','','20355'],
    '20':['Adrian Moen Hjartnes','','','27178'],
    '21':['Espen Strom','','','30203'],
    '22':['Kim Are Kold Gabrielsen','','','39848'],
    '23':['Erlend Rekkebo','','','40234'],
    '24':['Nils Terje Vihovde','','','41057'],
    '25':['Eivind Thorsen','','','42141'],
    '26':['Jonas Rocco Hansen','','','49150'],
    '27':['Anders Røstad','','','49327'],
    '28':['Ole Andre Buene Småland','','','55103'],
    '29':['Jonas Øvestad','','','58875'],
    '30':['Ole Nikolai Lie','','','61580'],
    '31':['Alfred Askvig','','','67133'],
    '32':['Finn Olav Haga','','','69858'],
    '33':['Tormod Herland','','','82491'],
    '34':['Håkon Tollefsen','','','83433'],
    '35':['Franco Roche','','','86052'],
    '36':['Mathias Madslien','','','89144'],
    '37':['Nikolai Skaue','','','91020'],
    '38':['Espen Langeveld','','','91226'],
    '39':['Petter Andreassen','','','108409'],
    '40':['Sondre Golden Midtgarden','','','116420'],
    '41':['Kjartan Hjermann Døhlie','','','124858'],
    '42':['Håvard Olsen','','','130323'],
    '43':['Christian Hjorth','','','144218'],
    '44':['Christian Westgaard','','','144441'],
    '45':['Karl Felix Krafft','','','154491'],
    '46':['Tobias Nymo Melseth','','','170405'],
    '47':['Espen Kloster','','','176098'],
    '48':['Lars Henrik Haugeli','','','179574'],
    '49':['Jørgen Røgeberg','','','185847'],
    '50':['Frederik Okland','','','196798'],
    '51':['Fredrik Løken','','','207203'],
    '52':['Ola Kalvø Vattøy','','','216082'],
    '53':['Emil Brotangen','','','230565'],
    '54':['Aleksander Svanberg','','','231594'],
    '55':['Tobias Midtlyng','','','235561'],
    '56':['Magnus Bryn','','','237199'],
    '57':['Steinar Aarsland','','','263610'],
    '58':['Jan Odegard','','','287346'],
    '59':['Kristoffer Eikrem','','','288045'],
    '60':['Svein Inge Østebø','','','303975'],
    '61':['Eirik Haarr','','','338413'],
    '62':['Robin Libråten','','','358433'],
    '63':['Mikkel Hoff Jensen','','','433569'],
    '64':['August Haug Bjerknæs','','','733888']
}

export default data;