const data = {
    '1':['Bjørnar Jakobsen','Arrizabalagarba','2640','581'],
    '2':['Ivar Mehl Olsen','U dog ie','2611','2210'],
    '3':['Henning Stien','Gabbes gjeng','2605','2951'],
    '4':['Felix Felixsen','Backups','2599','3763'],
    '5':['Jonas Øvestad','Lovalova','2594','4656'],
    '6':['Tormod Herland','Kråkebolle','2576','9002'],
    '7':['Daniel Bertelsen','Bergen Mcstropp','2574','9758'],
    '8':['Kristoffer Eikrem','Team name','2573','10106'],
    '9':['Levi Blindheim','Herslebs G19','2571','10808'],
    '10':['Jonas Fougner','FC Luckyfootball','2559','16285'],
    '11':['Martin Mikalsen','Gruppo Anti','2546','24074'],
    '12':['Jon Rømmen','Team Mink','2542','27066'],
    '13':['Øyvind Godø','Godø/Glimt','2542','27589'],
    '14':['Anders Lium','FplCyborg','2539','29507'],
    '15':['Espen Strom','Motstrøm','2534','34224'],
    '16':['Hallstein Brøtan','Halfstoned Halfballs','2526','41766'],
    '17':['endre refvik','Szoboszolainen','2523','44739'],
    '18':['Tor Arne Momrak','Fyresdal','2518','50648'],
    '19':['Jan Ødegård','Botman & Robbo','2517','51967'],
    '20':['Andreas Emblem Særsland','Særsland','2504','70568'],
    '21':['Endre Vigdal Øren','Det er rødt kort!!','2503','71585'],
    '22':['Emil Brotangen','Pitbulls','2498','79207'],
    '23':['Jonas Rocco Hansen','Zinédine Butane','2498','79805'],
    '24':['Espen Langeveld','Onana,WMN?SanderBørj','2497','81117'],
    '25':['Erlend Rekkebo','Team Rekkis','2497','81790'],
    '26':['Kjartan Hjermann Døhlie','Kjart1FC','2496','82511'],
    '27':['Tore Kristensen','Lloyds Boys','2488','96416'],
    '28':['Ola Kalvø Vattøy','Rice Rice Baby','2487','98651'],
    '29':['Jarle holgersen','General Forsamling','2486','100523'],
    '30':['Petter Meidell','Faller','2484','103995'],
    '31':['Henrik Haukom','FC Dampveivals','2479','113461'],
    '32':['Remy Snilstveit','Reggy’s XI','2479','114784'],
    '33':['Robert Leithe','Rustipikk','2476','119829'],
    '34':['Ørjan Knutsen','SirKnutsen','2475','122000'],
    '35':['Tobias Gunerius Hafrom','Earth, Wind&Maguire','2474','124788'],
    '36':['Alfred Askvig','Rice Rice Baby','2473','126718'],
    '37':['Nils Terje Vihovde','Flotmyr Video','2465','145049'],
    '38':['Christian Larsen','Drengens','2455','169483'],
    '39':['Eirik Nordengen','Northfields netters','2455','169865'],
    '40':['Fredrik Kolsrud','Mig','2454','172320'],
    '41':['Trond-Vegard Kristiansen','Asdf','2453','174732'],
    '42':['Oystein Wallevik','Raging Raisins','2440','214526'],
    '43':['Sofie R','Sddkfk','2439','216797'],
    '44':['Richard Gumpen Hasselberg','Cornelis Elander','2436','226586'],
    '45':['Frank Mundal','Losonjo FK','2430','245265'],
    '46':['Even Hansen','MP Boys','2430','245470'],
    '47':['Svein Inge Østebø','BadaBing','2430','245674'],
    '48':['Ådne Øvereng','Its coming home','2424','265862'],
    '49':['Frode Rekve','The Gluecifers','2421','278837'],
    '50':['Aleksander Nordahl','Axanor FC','2420','281687'],
    '51':['Øyvind Larsen','Team Sizo','2412','311620'],
    '52':['Besmir K','Orker Ikke','2412','312772'],
    '53':['Magnus Ask Mikkelsen','FC Fishparty','2411','316361'],
    '54':['Eirik Jokerud','Krødern Red Bulls','2411','317659'],
    '55':['Vegard Halgunset','Catch me if you Kane','2402','352729'],
    '56':['Anders Røstad','Lokomotiv Roztad','2402','355497'],
    '57':['Torgeir Overøye','ToggiBayor','2399','368185'],
    '58':['Markus Løland','Boondock Harriers','2387','422704'],
    '59':['Karl Felix Krafft','Felkarlix','2372','503851'],
    '60':['Johan Løken','Rimelig tvil','2366','538721'],
    '61':['Olve Engelsen','Conscindo','2362','562684'],
    '62':['Anders Kirchoff','Bricklayers','2358','590805'],
    '63':['Lars Henrik Haugeli','Larsenal FC','2327','805066'],
    '64':['Pål Schjerven','Schjervinhos','2312','932072'],    
}

export default data;