const data = {
'1':['Øyvind Larsen','Team Sizo','2699','1058'],
'2':['andreas heiberg','Team hebb','2698','1092'],
'3':['David Faukner Bendiksen','SlankBakfrasOrkester','2693','1302'],
'4':['Richard Gumpen Hasselberg','Cornelis Elander','2674','2450'],
'5':['Mats Oftedal','Oftis United','2663','3535'],
'6':['Jon Thomsen','Eg e her oppe, Rollo','2651','4969'],
'7':['Jørgen Stenseth','Bromstad Bonkers','2650','5119'],
'8':['Eirik Jokerud','Krødern Red Bulls','2634','8124'],
'9':['Ole Nikolai Lie','Hjertesukk','2632','8548'],
'10':['Torgeir Overøye','ToggiBayor','2620','11632'],
'11':['Kristoffer Hagen','Rubels&Diamonds','2615','13066'],
'12':['Magnus Tjelle','Rettferdighet','2615','13198'],
'13':['Bjarne Martinussen','1860 Solås','2614','13379'],
'14':['Kenneth Larsen','LaSSie Heath L&YR FC','2613','13610'],
'15':['Daniel Brenn','Brenn Mee','2612','14014'],
'16':['Runar Andersen','Revenge vol.23','2603','17577'],
'17':['arne barsnes','WesMantooth','2600','18520'],
'18':['Johannes Nessa','FK Nessa','2598','19487'],
'19':['Arild Formo','MrFormo','2597','19906'],
'20':['Aleksander Våge Nilsen','Ei Saa Peittää','2597','20103'],
'21':['Morten Tveito','FC Gribben','2595','20780'],
'22':['Øyvind Godø','Pepperoni-effekten','2587','24606'],
'23':['Øyvind Nordengen','Qatarkameratene','2584','26330'],
'24':['Robin Sulen','KirkWorks','2583','26584'],
'25':['Benjamin Våge Nilsen','Bennis','2582','27274'],
'26':['Stephan Haugsrud','ChroniclesOfRuddock','2581','27993'],
'27':['Morten Offerdal','Serverkræsj FC','2580','28431'],
'28':['Knut Masdal','Knutby90','2579','29060'],
'29':['Jesper Øiestad','Reisenissene','2576','31108'],
'30':['Christian Gjertveit','Langemann lurer alle','2572','33639'],
'31':['Sindre Mikkelsen Pedersen','Klopptimistene','2572','33709'],
'32':['Anders Lium','FplCyborg','2571','33898'],
'33':['Christian Aasen','Si Senor','2570','34790'],
'34':['Jarle Kjær Bremseth','Borg IF','2568','36062'],
'35':['Jonas Bergh-Johnsen','Sexes disipler','2567','36833'],
'36':['Tore Kalleberg','Stang Inn','2567','36874'],
'37':['Frank Mundal','Losonjo FK','2566','37375'],
'38':['Erlend Rekkebo','Team Rekkis','2559','42979'],
'39':['Torkel Wahl-Olsen','De Wahlistiske','2556','45092'],
'40':['Ole Martin Stabbetorp','Death Cab for Cutie','2555','45879'],
'41':['Ronny Greipsland','Bing Sporting Club','2552','48315'],
'42':['Frode Molnes','Sunshine Coast FC','2549','50756'],
'43':['Stian Nilsen Nornes','Supphamar Patriots','2548','51484'],
'44':['Pål Jonas Almås','Trent van Robertson','2537','61736'],
'45':['Christian Hunstad Flotvik','Matip in Dias','2528','71078'],
'46':['Knut Henrik Rolland','Champions of Europe','2527','72600'],
'47':['Jørgen Røgeberg','YogisPicnicBasket','2524','75906'],
'48':['Eivind Almhjell','Sifl','2522','78052'],
'49':['Jon Rømmen','Slakterns disipler','2520','80158'],
'50':['Mats Sigstad','Mats United','2519','81655'],
'51':['Pål Schjerven','Schjervinhos','2513','89230'],
'52':['Espen Langeveld','Moura ogDeÅtteUngene','2509','94018'],
'53':['Mathias Madslien','Seim Sportsverein','2504','100470'],
'54':['Kent Ims Borsheim','The Borsheims','2492','118584'],
'55':['Jon Braaten','Rock Bottom','2484','131254'],
'56':['Fredrik Kolsrud','Mig','2480','137269'],
'57':['Magnus Bryn','FC Maglinators','2479','139156'],
'58':['Gøran Birkeland','Rett i nebbet','2473','149232'],
'59':['Ola Svanheld','Qilin FC','2472','150501'],
'60':['Tom Eklund-Aarlia','VeniVidiVici','2462','169286'],
'61':['Håvard Ree Hanson','Canaries','2448','195896'],
'62':['Markus Løland','Boondock Harriers','2439','214138'],
'63':['Jorgen Perminow','Punisher FC','2413','275617'],
'64':['Felix Stephensen','Backups','2409','285730']
}

export default data;