const data = {
    '1':['Jesper Øiestad','Reisenissene','2662','196'],
    '2':['Torkel Wahl-Olsen','Don Lon is Red','2619','1575'],
    '3':['Mats Oftedal','Oftis United','2612','2201'],
    '4':['Runar Andersen','Revenge vol.23','2603','3222'],
    '5':['Bjarne Martinussen','1860 Stokke','2596','4238'],
    '6':['Tore Kalleberg','Stang Inn','2596','4256'],
    '7':['Ola Svanheld','Qilin FC','2581','7448'],
    '8':['Finn Olav Haga','Mike Duff Fan Club','2579','8263'],
    '9':['Jorgen Perminow','Punisher FC','2573','10148'],
    '10':['Arne Barsnes','WesMantooth','2572','10374'],
    '11':['Knut Masdal','Knutby90','2569','11479'],
    '12':['Knut Henrik Rolland','Enzo Caicedo','2569','11551'],
    '13':['Sigurd Holen','Banana Ljubljana','2568','11966'],
    '14':['Eivind Thorsen','Vandalay Industries','2566','13095'],
    '15':['Christian Gjertveit','Langemann lurer alle','2564','13696'],
    '16':['Endre Berg Leiren','Løsningsforslaget','2561','15093'],
    '17':['Johannes Nessa','Legg opp','2558','16778'],
    '18':['Sindre Askeland','Cinico','2558','16896'],
    '19':['Magnus Tjelle','Rettferdighet','2555','18365'],
    '20':['Daniel Brenn','Brenn Mee','2555','18365'],
    '21':['Ole Andre Buene Småland','Buenos Smålandos','2555','18462'],
    '22':['Morten Offerdal','Merseyside FC','2554','18985'],
    '23':['Morten Tveito','FC Gribben','2549','21987'],
    '24':['Jon Thomsen','Eg e her oppe, Rollo','2547','23560'],
    '25':['Ole Martin Stabbetorp','Death Cab for Cutie','2546','24325'],
    '26':['Frode Molnes','Sunshine Coast FC','2545','25252'],
    '27':['Øyvind Nordengen','Qatarkameratene','2544','25919'],
    '28':['Christian Hunstad Flotvik','Badeball','2539','29507'],
    '29':['Tom Eklund-Aarlia','VeniVidiVici','2536','32557'],
    '30':['Stephan Haugsrud','ChroniclesOfRuddock','2532','35539'],
    '31':['Petter Andreassen','Rohan Hotspur FC','2531','36464'],
    '32':['Stian Nilsen Nornes','Supphamar FK','2527','40702'],
    '33':['Ole Nikolai Lie','Hjertesalat','2524','43981'],
    '34':['Mats Sigstad','Mats United','2522','45829'],
    '35':['Nikolai Skaue','Skaue FC','2522','45829'],
    '36':['Aleksander Våge Nilsen','Ei Saa Peittää','2520','48486'],
    '37':['Jøran Solli','Shady Whims','2520','48884'],
    '38':['David Faukner Bendiksen','SlankBakfrasOrkester','2519','49612'],
    '39':['Jørgen Stenseth','BromstadBonkers','2519','49849'],
    '40':['Benjamin Våge Nilsen','Bennis','2515','54535'],
    '41':['Jarle Kjær Bremseth','Borg IF','2509','62434'],
    '42':['Christian Aasen','Sie Senor','2507','65220'],
    '43':['Kenneth Larsen','LaSSie Heath L&YR FC','2506','67078'],
    '44':['Håvard Olsen','Superlaget','2498','78827'],
    '45':['Jørgen Røgeberg','YogisPicnicBasket','2497','81443'],
    '46':['Magnar Nordtun','FC Magnars','2496','82876'],
    '47':['Arild Formo','MrFormo','2495','84736'],
    '48':['Jørgen Haugli Johansen','FC Full Sprut','2494','85790'],
    '49':['Jonas Bergh-Johnsen','Sexes’ Disipler','2488','96558'],
    '50':['Eivind Almhjell','Sifl','2487','98780'],
    '51':['Mathias Madslien','Pellen','2483','105253'],
    '52':['Christoffer Olsen','Vinodrama','2480','112515'],
    '53':['Ivar Langenes','Urban Pioneers','2476','120778'],
    '54':['Christian Westgaard','Beekmørkt','2465','145742'],
    '55':['Remi Håverstad','Team Ræmz','2460','157335'],
    '56':['Kristoffer Hagen','Rubels&Diamonds','2454','171811'],
    '57':['Robin Sulen','Tata','2451','180072'],
    '58':['Christian Hjorth','Paradise Regained','2445','198147'],
    '59':['Fredrik Løken','Freddy Rovers','2426','259084'],
    '60':['Gøran Birkeland','Danny Hamstrings','2421','277245'],
    '61':['Finn Sollie','Finncastle','2420','281495'],
    '62':['Ronny Greipsland','Bing Sporting Club','2420','281495'],
    '63':['Frank Pedersen','Worthless Men','2399','367966'],
    '64':['Andreas Heiberg','Team Hebb','2334','752721'],
}

export default data;