const data = {
'1':['Finn Sollie','Finncastle','2740','210'],
'2':['Christian Westgaard','Beekmørkt','2727','380'],
'3':['Sindre Askeland','Cinico','2709','742'],
'4':['Fredrik Løken','Freddy Rovers','2708','770'],
'5':['Remi Håverstad','Team Ræmz','2681','1990'],
'6':['Ole Andre Buene Småland','Buenos Smålandos','2678','2219'],
'7':['Eivind Thorsen','Vandalay Industries','2671','2680'],
'8':['Ivar Langenes','Urban Pioneers','2666','3190'],
'9':['Magnar Nordtun','FC Magnars','2649','5319'],
'10':['Nils Terje Vihovde','Sørhaug Søckers','2643','6295'],
'11':['Nikolai Skaue','Skaue FC','2642','6462'],
'12':['Eirik Nordengen','Northfields netters','2642','6500'],
'13':['Håvard Olsen','FK Flyt','2640','6844'],
'14':['Christian Larsen','Drengens','2634','7974'],
'15':['Hallstein Brøtan','Halfstoned Halfballs','2627','9800'],
'16':['Jan Ødegård','Game of Stones','2614','13519'],
'17':['Emil Brotangen','Pitbulls','2610','14785'],
'18':['Sigurd Holen','Banana Ljubljana','2606','16312'],
'19':['Espen Strom','Motstrøm','2603','17506'],
'20':['Kjartan Hjermann Døhlie','Kjart1 FC','2602','17729'],
'21':['Ole Kevin Rodberg','Snik United','2602','17906'],
'22':['Andreas Neegaard','Lord of the Ings','2600','18817'],
'23':['Ola Kalvø Vattøy','AuboomSakaLacca','2588','23967'],
'24':['Jonas Rocco Hansen','Zinédine Butane','2585','25780'],
'25':['Andreas Woll','Kefir','2583','26501'],
'26':['Svein Inge Østebø','BadaBing','2582','27114'],
'27':['Karl Felix Krafft','Mulligatoney','2581','27735'],
'28':['Besmir K','whatever','2567','37079'],
'29':['Anders Røstad','Konkrementene','2561','41078'],
'30':['Tormod Herland','Kokosbolle','2547','52335'],
'31':['Jonas Øvestad','Lovalova','2547','52518'],
'32':['Jarle holgersen','Violent femmes','2545','54028'],
'33':['Anders Kirchoff','Yaya Ding Dong','2545','54728'],
'34':['Petter Andreassen','Ayew Mee Son?','2544','55028'],
'35':['Tobias Nymo Melseth','Kjøretgår','2542','57101'],
'36':['Magnus Ask Mikkelsen','FC Fishparty','2539','59682'],
'37':['Trond-Vegard Kristiansen','A$$ KiCKERS UNiTED','2538','60907'],
'38':['Even Egeberg','Romedal City','2535','63629'],
'39':['Henning Stien','Gabbes gjeng','2535','63687'],
'40':['Frode Rekve','The Gluecifers','2528','71437'],
'41':['Olve Engelsen','Conscindo','2527','72053'],
'42':['Jørgen Haugli Johansen','FC Full Sprut','2526','73089'],
'43':['Lars Henrik Haugeli','Larsenal FC','2526','73232'],
'44':['Lars Egil Grimstad Pay','PARTYLAGET','2526','73448'],
'45':['Alfred Askvig','Men Fofana','2525','74317'],
'46':['Kristoffer Eikrem','opp og frem','2523','76846'],
'47':['Christian Hjorth','Paradise Lost','2518','82807'],
'48':['bjorn andenas','Bislet Barfighters','2514','87683'],
'49':['Øyvind Mundal','Munchester FC','2507','96720'],
'50':['Jøran Solli','Shady Whims','2507','97171'],
'51':['Finn Olav Haga','Godlia Åresalgslag','2505','99417'],
'52':['Kristian Bokn','Bokn Juniors','2504','100311'],
'53':['Lars Erik Ødegaard','UnitedStars','2503','102273'],
'54':['Håkon Aalmen','The Hooligans','2487','125301'],
'55':['Tommy Albertsen','Team deleted','NA','NA'],
'56':['Steinar Aarsland','Ings Sá Lewin Davies','2482','133623'],
'57':['Fabian von Harling','ThePeleOfMissionary','2474','147829'],
'58':['andreas fardal','Very well bekrutta','2472','150401'],
'59':['Espen Kloster','Kloster FC','2471','152294'],
'60':['Adrian Moen Hjartnes','AsianFascination','2463','166971'],
'61':['Tobias Simonnes','Andreball gutta','2457','178186'],
'62':['Aasmund Woldsengen','Team Naaldus','2432','230207'],
'63':['Lars Sjøvold','Loki','2424','248123'],
'64':['Kim Are Kold Gabrielsen','Team deleted','2435','NA']
}

export default data;