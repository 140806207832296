<template>

<div id="app">
    
    <div class="container" style="margin-top:20px">

        <span id="enter_manager" style="margin-bottom: 40px">
            <input v-model="managerId" placeholder="Enter manager id"> 
            <button v-on:click="getManagerLeaguesData" style="margin-left: 10px">Get manager data</button>
        </span>

        <div id="select_league" style="margin-top:20px;margin-bottom:20px" v-if="fetchedManager" @change="getLeagueInsightsData($event)">
           <b>Manager found: {{managerEntryData.managerName}}, Team name: {{managerEntryData.teamName}}.</b><br>
           CyborgScore: {{managerEntryData.cyborgScore}}, Seasons played: {{managerEntryData.seasonsPlayed}}, Top100Ks: {{managerEntryData.topHundredKs}}, Top10Ks: {{managerEntryData.topTenKs}}.<br>
           <br>Select a league to view League Insights data: 
            <b-form-select v-model="selectedLeagueId" size="lg">
                <option v-for="league in leagueOptions" v-bind:value="league.leagueId" :key="league.leagueId">
                    <b>{{league.leagueName}}</b>
                </option>
            </b-form-select>
        </div>

    <div id ="toggle_button_summary_view" v-if="fetchedLeague">
            League summary: 
            <toggle-button @change="toggleSummaryView()" :width="80" :height="30"
             :color="{checked: '#074680', unchecked: '#000407'}"
               :labels="{checked: 'Show', unchecked: 'Hide'}"/>
            <br>Data shown for up to top 100 managers, and updated until last deadline. 
    </div>

    <div id="league_summary" v-if="fetchedLeague&&showSummary&&!preSeason">
    
    <table id="summarytable">
    <tr>
        <th colspan=2>League template Team</th>
        <th colspan=1>Chip Usage</th>
    </tr>
    <tr>
        <td colspan=1 style="font-weight:bold">Goalkeepers:</td>
        <td colspan=1 style="font-weight:bold">Midfielders:</td>
        <td>WC1: {{leagueSummaryData.chipUsageList[0].percentage}} %</td>
    </tr>

    <tr>
        <td colspan=1>{{leagueSummaryData.goalkeepers[0].name}} ({{leagueSummaryData.goalkeepers[0].ownershipPercentageString}}%)</td>
        <td colspan=1>{{leagueSummaryData.midfielders[0].name}} ({{leagueSummaryData.midfielders[0].ownershipPercentageString}}%)</td>
        <td>BB: {{leagueSummaryData.chipUsageList[1].percentage}} %</td>
    </tr>
    <tr>
        <td colspan=1>{{leagueSummaryData.goalkeepers[1].name}} ({{leagueSummaryData.goalkeepers[1].ownershipPercentageString}}%)</td>
        <td colspan=1>{{leagueSummaryData.midfielders[1].name}} ({{leagueSummaryData.midfielders[1].ownershipPercentageString}}%)</td>
        <td>FH1: {{leagueSummaryData.chipUsageList[2].percentage}} %</td>
    </tr>
    <tr>
        <td colspan=1>{{leagueSummaryData.goalkeepers[2].name}} ({{leagueSummaryData.goalkeepers[2].ownershipPercentageString}}%)</td>
        <td colspan=1>{{leagueSummaryData.midfielders[2].name}} ({{leagueSummaryData.midfielders[2].ownershipPercentageString}}%)</td>
        <td>TC: {{leagueSummaryData.chipUsageList[3].percentage}} %</td>
    </tr>
    <tr>
        <td colspan=1>{{leagueSummaryData.goalkeepers[3].name}} ({{leagueSummaryData.goalkeepers[3].ownershipPercentageString}}%)</td>
        <td colspan=1>{{leagueSummaryData.midfielders[3].name}} ({{leagueSummaryData.midfielders[3].ownershipPercentageString}}%)</td>
        <td>WC2: {{leagueSummaryData.chipUsageList[4].percentage}} %</td>
    </tr>
    <tr>
        <td colspan=1 style="font-weight:bold">Defenders:</td>
        <td colspan=1>{{leagueSummaryData.midfielders[4].name}} ({{leagueSummaryData.midfielders[4].ownershipPercentageString}}%)</td>
    </tr>
    <tr>
        <td colspan=1>{{leagueSummaryData.defenders[0].name}} ({{leagueSummaryData.defenders[0].ownershipPercentageString}}%)</td>
        <td colspan=1>{{leagueSummaryData.midfielders[5].name}} ({{leagueSummaryData.midfielders[5].ownershipPercentageString}}%)</td>
    </tr>
    <tr>
        <td colspan=1>{{leagueSummaryData.defenders[1].name}} ({{leagueSummaryData.defenders[1].ownershipPercentageString}}%)</td>
        <td colspan=1>{{leagueSummaryData.midfielders[6].name}} ({{leagueSummaryData.midfielders[6].ownershipPercentageString}}%)</td>
    </tr>
    <tr>
        <td colspan=1>{{leagueSummaryData.defenders[2].name}} ({{leagueSummaryData.defenders[2].ownershipPercentageString}}%)</td>
        <td colspan=1 style="font-weight:bold">Attackers:</td>
    </tr>
    <tr>
        <td colspan=1>{{leagueSummaryData.defenders[3].name}} ({{leagueSummaryData.defenders[3].ownershipPercentageString}}%)</td>
        <td colspan=1>{{leagueSummaryData.attackers[0].name}} ({{leagueSummaryData.attackers[0].ownershipPercentageString}}%)</td>
    </tr>
    <tr>
        <td colspan=1>{{leagueSummaryData.defenders[4].name}} ({{leagueSummaryData.defenders[4].ownershipPercentageString}}%)</td>
        <td colspan=1>{{leagueSummaryData.attackers[1].name}} ({{leagueSummaryData.attackers[1].ownershipPercentageString}}%)</td>
    </tr>
    <tr>
        <td colspan=1>{{leagueSummaryData.defenders[5].name}} ({{leagueSummaryData.defenders[5].ownershipPercentageString}}%)</td>
        <td colspan=1>{{leagueSummaryData.attackers[2].name}} ({{leagueSummaryData.attackers[2].ownershipPercentageString}}%)</td>
    </tr>
    <tr>
        <td colspan=1>{{leagueSummaryData.defenders[6].name}} ({{leagueSummaryData.defenders[6].ownershipPercentageString}}%)</td>
        <td colspan=1>{{leagueSummaryData.attackers[3].name}} ({{leagueSummaryData.attackers[3].ownershipPercentageString}}%)</td>
    </tr>
 
    </table>
    </div> 

        <div id ="toggle_button_league_views" v-if="fetchedLeague">
            Select view: 
            <toggle-button @change="toggleSeasonData()" :width="80" :height="30"
             :color="{checked: '#074680', unchecked: '#000407'}"
               :labels="{checked: 'Historic', unchecked: 'Season'}"/>
        </div>

        <div id="status" v-if="fetching" style="margin-top: 10px">   
            {{status}}
            <b-spinner label="Fetching..."></b-spinner>
        </div>

        <div id="error" v-if="error&&!fetching">
          Sorry, something went wrong! {{status}} <a href="#" @click="getLeagueInsightsData($event)"> Click here to try again</a>
        </div>

        <div id= "league_season" v-if="fetchedLeague&&seasonData">
            <v-table class="table table-striped" :class="[{'table-dark': false}, 'table-bordered']" :data="managerDataList">
                <thead slot="head" class="thead-dark">
                <v-th sortKey="leagueRank" defaultSort="asc">Rank</v-th>
                <v-th sortKey="managerName">Manager</v-th>
                <v-th sortKey="teamnName">Team</v-th>            
                <v-th sortKey="overallPoints">Points</v-th>
                <v-th sortKey="overallRank">Overall Rank</v-th>   
                <v-th sortKey="cyborgScore">CyborgScore</v-th>
                <v-th sortKey="bestGwRank">Best GW Rank</v-th>
                <v-th sortKey="overallTemplateScore">Overall TemplateScore</v-th>
                <v-th sortKey="leagueTemplateScore">League TemplateScore</v-th>
                <v-th sortKey="totalPointsBenched">Total Benchpoints</v-th>            
                <v-th sortKey="aggressivityScore">AggroScore</v-th>
                <v-th sortKey="totalTransfers">Transfers</v-th>
                <v-th sortKey="totalHits">Hits</v-th>
                <v-th sortKey="teamValue">Team Value</v-th>  
                <v-th sortKey="wc1">WC1</v-th>
                <v-th sortKey="wc2">WC2</v-th>
                <v-th sortKey="fh1">FH</v-th>
                <v-th sortKey="bb">BB</v-th>
                <v-th sortKey="tc">TC</v-th>
                <v-th sortKey="favouriteTeam">Favourite Team</v-th>
                </thead>
                <tbody slot="body" slot-scope="{displayData}">
                <tr v-for="managerData in displayData" :key="managerData.managerName+managerData.managerId">
                    <td>{{managerData.leagueRank}}</td>
                    <td><a :href="getManagerHistoryLink(managerData.managerId)" target="_blank">{{managerData.managerName}}</a></td>
                    <td>{{managerData.teamName}}</td>                
                    <td>{{managerData.overallPoints}}</td>
                    <td>{{managerData.overallRank}}</td>
                    <td>{{managerData.cyborgScore}}</td>
                    <td>{{managerData.bestGwRank}}</td>
                    <td>{{managerData.overallTemplateScore}}</td>
                    <td>{{managerData.leagueTemplateScore}}</td>
                    <td>{{managerData.totalPointsBenched}}</td>                    
                    <td>{{managerData.aggressivityScore}}</td>
                    <td>{{managerData.totalTransfers}}</td>
                    <td>{{managerData.totalHits}}</td>
                    <td>{{managerData.teamValue}}</td>  
                    <td>{{managerData.wc1}}</td> 
                    <td>{{managerData.wc2}}</td> 
                    <td>{{managerData.fh1}}</td> 
                    <td>{{managerData.bb}}</td> 
                    <td>{{managerData.tc}}</td> 
                    <td>{{managerData.favouriteTeam}}</td>
                    </tr>
                </tbody>
            </v-table>
        </div>
        <div id= "league_historic" v-if="fetchedLeague&&!seasonData">
            <v-table class="table table-striped" :class="[{'table-dark': false}, 'table-bordered']" :data="managerDataList">
                <thead slot="head" class="thead-dark">
                <v-th sortKey="leagueRank" defaultSort="asc">Current Rank</v-th>
                <v-th sortKey="managerName">Manager</v-th>
                <v-th sortKey="managerId">ManagerId</v-th>
                <v-th sortKey="teamName">Team Name</v-th>
                <v-th sortKey="seasonsPlayed">Seasons Played</v-th>
                <v-th sortKey="averageRank">Average Rank</v-th>
                <v-th sortKey="bestRank">Best Rank</v-th>
                <v-th sortKey="top10Ks"># Top10Ks</v-th>
                <v-th sortKey="top100Ks"># Top100Ks</v-th>
                <v-th sortKey="cyborgScore">CyborgScore</v-th>
                </thead>
                <tbody slot="body" slot-scope="{displayData}">
                <tr v-for="managerData in displayData" :key="managerData.managerName+managerData.managerId">
                    <td>{{managerData.leagueRank}}</td>
                    <td><a :href="getManagerHistoryLink(managerData.managerId)" target="_blank">{{managerData.managerName}}</a></td>
                    <td>{{managerData.managerId}}</td>
                    <td>{{managerData.teamName}}</td>
                    <td>{{managerData.seasonsPlayed}}</td>
                    <td>{{managerData.averageRank}}</td>
                    <td>{{managerData.bestRank}}</td>
                    <td>{{managerData.top10Ks}}</td>
                    <td>{{managerData.top100Ks}}</td>
                    <td>{{managerData.cyborgScore}}</td>
                    </tr>
                </tbody>
            </v-table>
        </div>
    </div>
</div>

</template>

<script>    
    import axios from 'axios'

export default {
  name: 'app',
  components: {},
  data() { 
        return {
            status : '',
            error : false,
            managerId : '',
            fetching: false,
            fetchedLeague : false,
            fetchedManager : false,
            seasonData: true,
            showSummary: true,
            managerEntryData : {},
            selectedLeagueId : '',
            leagueOptions: [
                {leagueName: 'Select league', leagueId: ''}    
            ],
            managerDataList : [],
            leagueSummaryData : [],
            preSeason: false
            }
        },
        mounted: function () {
            this.backendWakeUp();
        },
        methods: {
            backendWakeUp: function () {
                axios.get('https://fplcyborg-be.herokuapp.com/wakeUp/')
                .then(wakeUpResponse => {
                    console.log('wakeUpStatus:', wakeUpResponse.data);
                })
            },
            getManagerLeaguesData: function () {         
                var fpc_be_url = 'https://fplcyborg-be.herokuapp.com/managerBasicData/'+this.managerId; 
                this.fetching = true;
                this.fetchedManager = false; 
                this.fetchedLeague = false;
                this.status = 'Getting data from FPL';
                axios.get(fpc_be_url)
                    .then(response => {
                        this.managerEntryData = response.data;
                        this.leagueOptions = response.data.classicLeagueDataList;
                        this.status = 'Data fetched ok';
                        this.fetching = false;
                        this.fetchedManager = true;
                    })
                    .catch(error => {
                        console.log(error);
                        this.fetching = false;
                        this.error=true;
                        this.status = 'Error fetching data for manager';
                    });
            },
            getLeagueInsightsData: function (event) {         
                
                var leagueId = event.target.value;
                if (leagueId == null) {
                    leagueId = this.selectedLeagueId;
                }
                console.log('Getting league data for', leagueId)
                var fpc_be_url = 'https://fplcyborg-be.herokuapp.com/leagueInsightsReactive/'+ leagueId;
                
                this.fetching = true;
                this.fetchedLeague = false; 
                this.status = 'Getting data from FPL';

                axios.get(fpc_be_url)
                    .then(leagueResponse => {
                        this.managerDataList = leagueResponse.data.managerDataList;
                        this.leagueSummaryData = leagueResponse.data.leagueSummaryData;
                        this.leagueName = leagueResponse.data.leagueName;
                        this.fetching = false;
                        if (leagueResponse.data.currentGw == 0) {
                            this.preSeason = true;
                        }
                        if (leagueResponse.data.errorMsg!=null) {
                          this.status = leagueResponse.data.errorMsg;
                          this.error = true;
                        } else {
                          this.status = 'Data fetched ok';
                          this.fetchedLeague = true;
                          this.error= false;
                        } 
                    })
                    .catch(error => {
                        console.log(error);
                        this.fetching = false;
                        this.error = true;
                        this.status = "Something went wrong"
                    });
            },
            
            getManagerHistoryLink(id) {
                return "https://fantasy.premierleague.com/entry/"+id+"/history"
            },
            toggleSeasonData() {
                this.seasonData = !this.seasonData;
            },
            toggleSummaryView() {
                this.showSummary = !this.showSummary;
            }
    }
} 
</script>

<style>
    [v-cloak] {
        display: none;
    }
</style>