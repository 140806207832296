const data = {
    '1':['Kristoffer Hagen','Rubels&Diamonds','2660','971'],
    '2':['Mats Oftedal','Oftis United','2642','2305'],
    '3':['Sindre Askeland','Cinico','2629','4027'],
    '4':['Runar Andersen','Revenge vol.23','2628','4189'],
    '5':['Tore Kalleberg','Stang Inn','2619','6110'],
    '6':['Finn Sollie','Finncastle','2619','6176'],
    '7':['Ole Martin Stabbetorp','Death Cab for Cutie','2618','6435'],
    '8':['Ole Nikolai Lie','Hjertesalat','2616','6893'],
    '9':['Jørgen Stenseth','Bromstad Bonkers','2616','6974'],
    '10':['Bjarne Martinussen','Urettferdighet','2614','7391'],
    '11':['Knut Masdal','Knutby90','2606','10104'],
    '12':['Benjamin Våge Nilsen','Bennis','2603','11262'],
    '13':['Christian Hunstad Flotvik','Badeball','2601','12234'],
    '14':['Torkel Wahl-Olsen','Don Lon is Red','2600','12651'],
    '15':['Christian Aasen','Sie Senor','2592','16642'],
    '16':['Kenneth Larsen','LaSSie Heath L&YR FC','2589','18611'],
    '17':['Håvard Olsen','FK Flyt','2588','19169'],
    '18':['Jesper Øiestad','Reisenissene','2588','19292'],
    '19':['Magnus Tjelle','Rettferdighet','2588','19342'],
    '20':['Stian Nilsen Nornes','Supphamar FK','2584','22049'],
    '21':['Christian Westgaard','Beekmørkt','2583','22710'],
    '22':['Morten Offerdal','Serverkræsj 2.0','2582','23477'],
    '23':['Stephan Haugsrud','ChroniclesOfRuddock','2581','23958'],
    '24':['Christian Gjertveit','Langemann lurer alle','2580','24991'],
    '25':['Jon Thomsen','Eg e her oppe, Rollo','2579','25555'],
    '26':['Knut Henrik Rolland','High Team ID chaser','2574','29850'],
    '27':['Magnar Nordtun','FC Magnars','2574','29892'],
    '28':['Nikolai Skaue','Skaue FC','2570','33717'],
    '29':['Arild Formo','MrFormo','2570','33889'],
    '30':['David Faukner Bendiksen','SlankBakfrasOrkester','2564','39790'],
    '31':['Johannes Nessa','FK Nessa','2558','47291'],
    '32':['Arne Barsnes','WesMantooth','2557','48363'],
    '33':['Remi Håverstad','Team Ræmz','2556','50024'],
    '34':['Jørgen Røgeberg','YogisPicnicBasket','2556','50415'],
    '35':['Øyvind Nordengen','Qatarkameratene','2555','51857'],
    '36':['Robin Sulen','Jabbadabbadu','2554','52319'],
    '37':['Frode Molnes','Sunshine Coast FC','2551','57200'],
    '38':['Eivind Almhjell','Sifl','2549','60101'],
    '39':['Ivar Langenes','Urban Pioneers','2548','62070'],
    '40':['Andreas Heiberg','Team Hebb','2537','80285'],
    '41':['Eivind Thorsen','Vandalay Industries','2530','95019'],
    '42':['Ronny Greipsland','Bing Sporting Club','2529','96248'],
    '43':['Fredrik Løken','Freddy Rovers','2528','99265'],
    '44':['Ole Andre Buene Småland','Buenos Smålandos','2524','107950'],
    '45':['Jonas Bergh-Johnsen','Sexes disipler','2524','108458'],
    '46':['Jarle Kjær Bremseth','Borg IF','2520','117875'],
    '47':['Morten Tveito','FC Gribben','2518','121961'],
    '48':['Mats Sigstad','Mats United','2517','124481'],
    '49':['Aleksander Våge Nilsen','Ei Saa Peittää','2517','125320'],
    '50':['Daniel Brenn','Brenn Mee','2516','127081'],
    '51':['Erlend Rekkebo','Team Rekkis','2514','133329'],
    '52':['Torgeir Overøye','ToggiBayor','2502','165941'],
    '53':['Øyvind Godø','Pepperoni-effekten','2502','165941'],
    '54':['Jon Rømmen','Slakterns menn','2499','173739'],
    '55':['Nils Terje Vihovde','Flotmyr Video','2491','200426'],
    '56':['Anders Lium','Clairvoyants','2484','224447'],
    '57':['Richard Gumpen Hasselberg','Cornelis Elander','2482','231837'],
    '58':['Christian Larsen','Drengens','2466','293619'],
    '59':['Pål Schjerven','Schjervinhos','2466','293619'],
    '60':['Pål Jonas Almås','Trent van Robertson','2426','493103'],
    '61':['Eirik Nordengen','Northfields netters','2425','498784'],
    '62':['Frank Mundal','Losonjo FK','2420','528656'],
    '63':['Øyvind Larsen','Team Sizo','2413','571568'],
    '64':['Espen Langeveld','Onomahtopoetikon','2412','578214'],
}

export default data;