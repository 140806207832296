const data = {
'1':['Runar Andersen','Revenge vol.23','2475','112'],
'2':['Eivind Almhjel','sifl','2442','461'],
'3':['Torkel Wahl-Olsen','De Wahlistiske','2431','750'],
'4':['Eirik Jokerud','Krødern Red Bulls','2411','1668'],
'5':['Anders Lium','AFC Helvete','2398','2773'],
'6':['Tore Kalleberg','Stang Inn','2373','6421'],
'7':['Jon Braaten ','Rock Bottom','2371','6978'],
'8':['Ronny Greipsland','Bing Sporting Club','2368','7688'],
'9':['Sindre Askeland','Cinico','2365','8414'],
'10':['Magnus Bjartnes','Norwegian Greenwood','2361','9447'],
'11':['Anders Kirchoff','','2359','10247'],
'12':['Fredrik Kolsrud','','2356','11195'],
'13':['Øyvind Nordengen','','2355','11579'],
'14':['Jesper Øiestad','','2336','19587'],
'15':['Magnar Nordtun','','2336','19933'],
'16':['Frank Mundal','','2333','21659'],
'17':['Espen Bjørkeng','','2330','23579'],
'18':['Andreas Heiberg','','2329','23972'],
'19':['Morten Offerdal','','2329','24124'],
'20':['Kenneth Larsen','','2323','28215'],
'21':['Jonas Bergh Johnsen','','2317','32860'],
'22':['Kent Borsheim','','2314','35500'],
'23':['Aleksander Våge','','2312','37206'],
'24':['Paal Schjerven','','2306','43028'],
'25':['Ole Martin Stabbetorp','','2303','46195'],
'26':['Tommy Albertsen','','2300','49431'],
'27':['Markus Løland','','2299','50699'],
'28':['Kristian Bokn','','2290','61228'],
'29':['Jørgen Perminow','','2287','64960'],
'30':['Tom Eklund-Årlia','','2283','71765'],
'31':['Øyvind Godø','','2283','71765'],
'32':['Jarle Kjær Bremseth','','2292','58702'],
'33':['Bjarne Martinussen','','2282','73277'],
'34':['Bjørn Andenæs','','2280','75578'],
'35':['Frode Molnes','','2279','77894'],
'36':['Øyvind Larsen','','2278','79563'],
'37':['Stephan Haugsrud','','2277','80237'],
'38':['Knut Masdal','','2277','80991'],
'39':['Christian Flotvik','','2276','82623'],
'40':['Arne Barsnes','','2274','85587'],
'41':['Pål Jonas Almås','','2273','87768'],
'42':['Even Egeberg','','2270','92857'],
'43':['David Bendiksen','','2267','98418'],
'44':['Mats Oftedal','','2267','98758'],
'45':['Jon Thomsen','','2366','100745'],
'46':['Benjamin Våge','','2260','111263'],
'47':['Jøran Solli','','2259','114032'],
'48':['Richard Gumpen','','2256','120549'],
'49':['Mats Sigstad','','2256','120703'],
'50':['Ole Kevin Rodberg','','2249','137598'],
'51':['Hallstein Brøtan','','2248','138705'],
'52':['Johannes Nessa','','2246','145191'],
'53':['Gøran Birkeland','','2220','219916'],
'54':['Kåre Sagård','','2218','227235'],
'55':['Torgeir Overøye','','2195','317649'],
'56':['John Arne Ljosland','','2192','329836'],
'57':['Remi Håverstad','','2191','333943'],
'58':['Jon Rømmen','','2183','370178'],
'59':['Gøran Thengs','','2166','459128'],
'60':['Eivind Thane','','2156','517648'],
'61':['Magnus Mikkelsen','','2129','692010'],
'62':['Andreas Fardal','','2120','754989'],
'63':['Kristoffer Hagen','','2113','808308'],
'64':['Lars Sjøvold','','2034','1527887']
}

export default data;