<template>
  <div class="update">
    Updates:<br>
    <button v-on:click="updateManagerDbData('?updateType=Template&managerSelection=E64Nor')" style="margin-left: 10px">E64Nor template</button><br>
    <button v-on:click="updateManagerDbData('?updateType=Deadline&managerSelection=E64Nor')" style="margin-left: 10px">E64Nor deadline</button><br>
    <button v-on:click="updateManagerDbData('?updateType=Template&managerSelection=TopManagers')" style="margin-left: 10px">Top template</button><br>
    <button v-on:click="updateManagerDbData('?updateType=Deadline&managerSelection=Top10k')" style="margin-left: 10px">Top10k deadline</button><br>
    <br>
    <button v-on:click="updateManagerDbData('?updateType=EndOfGw&managerSelection=E64Nor')" style="margin-left: 10px">E64Nor EoGw</button><br>
    <button v-on:click="updateManagerDbData('?updateType=EndOfGw&managerSelection=Top10k')" style="margin-left: 10px">Top10k EoGw</button><br>

    
    Status: {{ status }} <br>
    Response: {{ response }}
    
   
  </div>
</template>
<script>    
import axios from 'axios'
export default {
  name: 'app',
  components: {},
  data() { 
        return {
            status : '',
            fetching : false,
            response : ''
        }
      },
      methods: {
        updateManagerDbData: function (inputString) {         
                var fpc_be_url = 'https://fplcyborg-be.herokuapp.com/updateManagerDbData/'+inputString; 
                this.fetching = true;
                this.fetchedManager = false; 
                this.fetchedLeague = false;
                this.status = 'Running update';
                axios.get(fpc_be_url)
                    .then(response => {
                        this.status = 'Updated ok';
                        this.response = response.data;
                        this.fetching = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.fetching = false;
                        this.status = 'Error running update';
                    });
            },
      }
    }

</script>

