<template>
    <div class="container" id="container">

        <div id="status" v-if="fetching" style="margin-top: 10px">   
            {{status}}
            <b-spinner label="Loading..."></b-spinner>
        </div>

         <div id="error" v-if="error&&!fetching">
          Sorry! {{status}} <a href="#" @click="getFirstPage()"> Click to try again</a>
        </div>

        <div id= "top100list" v-if="!error&&fetchedFirstPage">
            <br>
            Top CyborgRanks for country: Norway. <a href="#docview"> Documentation here.</a>
            <div id="pagination">
                <button v-if="offset>0"><a href="#" @click="getNewPage(false)"> Previous page</a></button>
                Showing page {{ offset +1 }} of {{ totalPages }}
                <button v-if="offset<totalPages"><a href="#" @click="getNewPage(true)"> Next page</a></button>
            </div>
            <div id="managertable">
            <v-table class="table table-striped" :class="[{'table-dark': false}, 'table-bordered']" :data="managerDataList">
                <thead slot="head" class="thead-dark">
                <v-th sortKey="cyborgRank" defaultSort="asc">CyborgRank</v-th>
                <v-th sortKey="managerName">Manager</v-th>
                <v-th sortKey="cyborgScore">CyborgScore</v-th>
                <v-th sortKey="overallRank">OverallRank</v-th>
                <v-th sortKey="topTenKs"># Top10Ks</v-th>
                <v-th sortKey="topHundredKs"># Top100Ks</v-th>
                <v-th sortKey="eliteNorType">Elite64Nor member</v-th>
                </thead>
                <tbody id="managerList" v-if="!error&&(fetchedFirstPage||fetchedNewPage)" slot="body" slot-scope="{displayData}">
                <tr v-for="managerData in displayData" :key="managerData.managerName+managerData.managerId">
                    <td>{{managerData.cyborgRank}}
                        <span :style="getValueDeltaRankColor(managerData.cyborgRank, managerData.lastCyborgRank)">
                            ({{getValueDeltaRank(managerData.cyborgRank, managerData.lastCyborgRank)}})</span>
                    </td>
                    <td><a :href="getManagerHistoryLink(managerData.managerId)" target="_blank">{{managerData.managerName}}</a></td>              
                    <td>{{managerData.cyborgScore}} 
                        <span :style="getValueDeltaScoreColor(managerData.cyborgScore, managerData.lastCyborgScore)">
                            ({{getValueDeltaScore(managerData.cyborgScore, managerData.lastCyborgScore)}})</span>
                    </td>
                    <td>{{managerData.overallRank}}
                        <span :style="getValueDeltaRankColor(managerData.overallRank, managerData.lastOverallRank)">
                            ({{getValueDeltaRank(managerData.overallRank, managerData.lastOverallRank)}})</span>
                    </td>
                    <td>{{managerData.topTenKs}}</td>
                    <td>{{managerData.topHundredKs}}</td>
                    <td>{{managerData.eliteNorType}}</td>
                    </tr>
                </tbody>
            </v-table>
            </div>
        </div> 
           
            <div id = "docview">
            Documentation: (<a href="#top100list">Go back to top</a>)
            <ul>
            <li>The table features the managers ranked by highest CyborgScore in selecte country, updated per GW in the current season </li>
            <li><b>CyborgScore</b> is calculated based on the current season and the 5 past seasons. Only top100k ranks are counted and most recent seasons are weighted higher than older seasons</li>
            <li>The current season is weighted according to the number of GWs played. E.g. after GW18 the season is weighted 50%</li>
            </ul>
        </div>
    </div>

</template>

<script>    
    import axios from 'axios'

export default {
  name: 'app',
  components: {},
  data() { 
        return {
            status : '',
            error : false,
            fetching: false,
            fetchedFirstPage : false,
            fetchedNewPage: false,
            currentGw : '',
            lastFinishedGw : '',
            managerDataList : [],
            leagueSummaryData : [],
            showFaq: false,
            offset: 0,
            totalPages: 5
            }
        },
        mounted: function () {
            this.getFirstPage();
        },
        methods: {
            getFirstPage: function () {        
                var fpc_be_url = 'https://fplcyborg-be.herokuapp.com/getManagerDbDataPage/Norway/?limit=100&offset=0';

                this.fetching = true;
                this.fetchedFirstPage = false; 
                this.status = 'Getting data from FPL';

                axios.get(fpc_be_url)
                    .then(top100Response => {
                        //console.log('got top100Response', top100Response.data);
                        this.managerDataList = top100Response.data;
                        this.currentGw = top100Response.data.currentGw;
                        //this.lastFinishedGw = top100Response.data.lastFinishedGw;
                        this.status = 'Data fetched ok';
                        this.fetching = false;
                        this.fetchedFirstPage = true;
                        //this totalPages = top100Response.headers.
                    })
                    .catch(error => {
                        console.log(error);
                        this.fetching = false;
                        this.fetchedFirstPage = true;
                        this.error = true;
                        this.status = 'Error fetching data';
                    });
            },
            getNewPage: function (isNext) {       
                if (isNext) {
                    this.offset = this.offset +1;
                } else {
                    this.offset = this.offset -1;
                }
                var url = 'https://fplcyborg-be.herokuapp.com/getManagerDbDataPage/Norway/?limit=100&offset=' + this.offset;

                this.fetching = true;
                this.fetchedNewPage = false; 
                this.status = 'Getting newPage with url' + url;

                axios.get(url)
                    .then(nextPageResponse => {
                        //console.log('got nextPageResponse', nextPageResponse.data);
                        this.managerDataList = nextPageResponse.data;
                        this.status = 'Data fetched ok';
                        this.fetching = false;
                        this.fetchedNewPage = true;
                    })
                    .catch(error => {
                        console.log(error);
                        this.fetching = false;
                        this.fetchedNewPage = true;
                        this.error = true;
                        this.status = 'Error fetching nextPageResponse';
                    });
            },
            getManagerHistoryLink(id) {
                return "https://fantasy.premierleague.com/entry/"+id+"/history"
            },
            getDeltaString(delta) {
                var deltaString = (Math.round(delta* 100) / 100).toFixed(1)
                if (delta<0) {
               return  deltaString
            }   else {
                return '+' + deltaString; 
               }
            },
            getDeltaColor(delta) {
              if (delta<0) {
                return "color:red";
              } else if (delta >0) {
                return "color:green";
              } else {
                return "color:black"
              }
            },
            getValueDeltaRankColor(newValue, lastValue) {
            var delta = this.getValueDeltaRank(newValue, lastValue);
              if (delta>0) {
                return "color:red";
              } else if (delta <0) {
                return "color:green";
              } else {
                return "color:black"
              }
            },
            getValueDeltaRank(newValue, lastValue) {
              var delta = newValue - lastValue;
              if (delta > 0) {
                delta = '+' + delta;
              } else if (delta == 0) {
                delta = '-';
              }
              return delta;
            },
            getValueDeltaScoreColor(newValue, lastValue) {
              var value = this.getValueDeltaScore(newValue, lastValue);
              return this.getDeltaColor(value);
            },
            getValueDeltaScore(newValue, lastValue) {
                var delta = newValue - lastValue;
                delta = Math.round(delta*100)/100;
                if (delta>0) {
                    delta = '+' + delta;
                } else if (delta == 0) {
                    delta = '-';
                }
                return delta;
            },
            toggleFaq() {
                this.showFaq = !this.showFaq;
            }
    }
} 
</script>

<style>
    [v-cloak] {
        display: none;
    }
    #container {
        margin-top:20px;
        display: flex;
        flex-direction: column;
        max-width: 1070px;
    }
    #summarytable th, #summarytable td{
    padding:0.2em;
    border: 1px inset;
    text-align: center;
    }
    #summarytable th{
    background-color:#1e1e1f;
    color: #f9f9fc;
    font-weight: bold;
    }
    
    #faqview{
    font-size: 12px;
    }
    #pagination{
        display: flex;
        justify-content: center;
        align-items: center;
        border: solid 1px;
    }
    #pagination button{
        border:none;
        margin: 1em;
        background-color: lightgray;
        border-radius:8px;
        position: inherit;
    }

</style>