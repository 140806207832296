const data = {
    '1':['Torkel Wahl-Olsen ','De Wahlistiske','2484','473'],
    '2':['Christian Larsen ','Drengens','2482','502'],
    '3':['Aleksander Våge Nilsen ','Eii Saa Peittää','2475','659'],
    '4':['Mats Oftedal ','Oftis United','2465','952'],
    '5':['Gøran Birkeland ','JLingz','2464','997'],
    '6':['Jøran Solli ','Shady whims','2460','1135'],
    '7':['Jarle Kjær Bremseth ','Borg IF','2440','2111'],
    '8':['Pål Jonas Almås ','Keitalah Manino','2435','2441'],
    '9':['arne barsnes ','WesMantooth','2428','3036'],
    '10':['Morten Offerdal ','Merseyside fc','2415','4439'],
    '11':['Hallstein Brøtan ','Halfstoned Halfballs','2414','4596'],
    '12':['Markus Løland ','Boondock Harriers','2412','4864'],
    '13':['Mats Sigstad ','Mats United','2394','7896'],
    '14':['Remi Håverstad ','Team Ræmz','2394','8047'],
    '15':['Eivind Thane ','Ji-sung Parkthebus','2390','8848'],
    '16':['Ole Martin Stabbetorp ','Death Cab for Cutie','2387','9533'],
    '17':['Even Egeberg ','Romedal City','2381','10956'],
    '18':['Øyvind Godø ','Pepperoni-effekten','2380','11283'],
    '19':['Kristian Bokn ','Bokn Juniors','2376','12289'],
    '20':['bjorn andenas ','Bislet Barfighters','2376','12408'],
    '21':['Eirik Jokerud ','Krødern Red Bulls','2375','12806'],
    '22':['Sindre Askeland ','Cinico','2367','15211'],
    '23':['Jon Thomsen ','Bleeding Doll','2367','15259'],
    '24':['Øyvind Larsen ','Team Sizo','2365','15976'],
    '25':['Magnar Nordtun ','Bandar Seri Begawan','2364','16329'],
    '26':['Anders Røsvik ','Aassahonden FC','2358','18599'],
    '27':['Jorgen Perminow ','FC Punisher','2357','19012'],
    '28':['Tommy Albertsen ','The Trooper','2357','19228'],
    '29':['Lars Sjøvold ','Dr. Nira Cain-N’Dege','2351','21728'],
    '30':['Jon Rømmen ','One time','2345','24568'],
    '31':['andreas fardal ','L. Digne Navarsete','2340','27368'],
    '32':['Magnus Woll Bjartnes ','Allardyce Hotel','2336','29659'],
    '33':['Torgeir Overøye ','ToggiBayor','2333','31635'],
    '34':['Johannes Nessa ','Grealish Fever','2331','32983'],
    '35':['Christian Hunstad Flotvik ','Team Badeball','2327','35576'],
    '36':['andreas heiberg ','team hebb','2323','38286'],
    '37':['Anders Kirchoff ','Søppeldynga','2323','38593'],
    '38':['Eivind Almhjell ','Sifl','2323','38693'],
    '39':['Paal Schjerven ','Schjervinhos','2322','39158'],
    '40':['Frank Mundal ','Xhakattack','2321','39996'],
    '41':['Bjarne Martinussen ','Borussia Uflyt','2318','42445'],
    '42':['Kenneth Larsen ','LaSSie Heath L&YR FC','2308','50659'],
    '43':['Benjamin Våge Nilsen ','Benni´s','2307','51689'],
    '44':['Tom Eklund-Aarlia ','Veni Vidi Vici','2305','53847'],
    '45':['Anders Lium ','AFC Helvete','2293','66408'],
    '46':['Gøran Østerman Thengs ','Herbert Markhughes','2291','68873'],
    '47':['Magnus Ask Mikkelsen ','FC Fishparty','2281','81591'],
    '48':['Øyvind Nordengen ','Øyvindzen','2279','84348'],
    '49':['Tore Kalleberg ','Stang Inn','2274','91544'],
    '50':['Richard Gumpen Hasselberg ','Cornelis Elander','2273','93144'],
    '51':['Frode Molnes ','Sunshine Coast FC','2273','93144'],
    '52':['David Faukner Bendiksen ','SlankBakfrasOrkester','2270','97657'],
    '53':['Ronny Greipsland ','Bing Sporting Club','2258','117723'],
    '54':['Jesper Øiestad ','Chill Town','2253','127413'],
    '55':['Runar Andersen ','Revenge vol.23','2251','131921'],
    '56':['Espen Bjørkeng ','Lokomotivet','2245','143804'],
    '57':['Fredrik Kolsrud ','Mig','2241','153085'],
    '58':['Ole Kevin Rodberg ','Snik United','2224','196212'],
    '59':['Kristoffer Hagen ','Rubels&Diamonds','2222','201162'],
    '60':['Knut Masdal ','Knutby90','2217','215004'],
    '61':['John Arne Ljosland ','umtiti','2207','247106'],
    '62':['Kent Trodal Borsheim ','The Borsheims','2195','289488'],
    '63':['Kaare Sagaard ','Kaares Kanon','2179','353521'],
    '64':['Thommas Sletten ','Scouserpool','2139','562684']
}

export default data;