const data = {
'1':['Knut Henrik Rolland','Franks at the wheel','2580', '358'],
'2':['Kristoffer Hagen','Rubels&Diamonds','2562', '779'],
'3':['Morten Tveito','FC Gribben','2560', '838'],
'4':['Mathias Madslien','mathias','2549', '1312'],
'5':['Erlend Rekkebo','Team Rekkis','2541', '1865'],
'6':['Torgeir Overøye','ToggiBayor','2539', '2007'],
'7':['Ole Nikolai Lie','Hjertesukk','2534', '2376'],
'8':['Gøran Birkeland','Rett i nebbet','2527', '3143'],
'9':['Magnus Bryn','FC Maglinators','2522', '3697'],
'10':['Johannes Nessa','Grisefaen','2515', '4740'],
'11':['Jon Rømmen','One time?','2507', '6077'],
'12':['Christian Aasen','Christians','2498', '8175'],
'13':['Jørgen Røgeberg','YogisPicnicBasket','2491', '10185'],
'14':['Espen Langeveld','Vodka Redmond','2491', '10294'],
'15':['Eivind Thorsen','Vandalay Industries','2486', '11818'],
'16':['Christian Westgaard','Beekmørkt','2478', '14966'],
'17':['Ola Kalvø Vattøy','AubomSakaLaca','2475', '16093'],
'18':['Anders Røstad','Sanatoriekameratene','2475', '16233'],
'19':['Nils Terje Vihovde','Jonnan, Dopi & Æsen','2473', '17022'],
'20':['Christian Hjorth','Paradise Lost','2471', '18008'],
'21':['Remi Håverstad','Team Ræmz','2467', '20021'],
'22':['Kjartan Hjermann Døhlie','Kjart1 FC','2465', '20998'],
'23':['Håkon Aalmen','The Hooligans','2457', '25715'],
'24':['Ole Andre Buene Småland','Buenos Smålandos','2452', '29168'],
'25':['Adrian Moen Hjartnes','AsianFascination','2451', '29989'],
'26':['Emil Brotangen','Pitbulls','2441', '37704'],
'27':['Tormod Herland','Kokosbolle','2438', '40534'],
'28':['Lars Sjøvold','Green In Reguilón','2435', '42957'],
'29':['Hallstein Brøtan','Halfstoned Halfballs','2431', '47116'],
'30':['Nikolai Skaue','Jompa XI','2429', '49232'],
'31':['Håvard Olsen','FK Flyt','2424', '54984'],
'32':['Besmir Kajolli','Wood for sheep?','2420', '59597'],
'33':['Lars Henrik Haugeli','Larsenal FC','2418', '61813'],
'34':['andreas fardal','No I in Teamocil','2417', '62799'],
'35':['Jonas Rocco Hansen','Zinédine Butane','2411', '71506'],
'36':['Finn Olav Haga','Goodhill United','2409', '73490'],
'37':['Svein Inge Østebø','BadaBing','2404', '81027'],
'38':['Trond-Vegard Kristiansen','A$$ KiCKERS UNiTED','2401', '85500'],
'39':['Magnus Ask Mikkelsen','FC Fishparty','2399', '88975'],
'40':['Steinar Aarsland','Are ya winning, Son?','2393', '98506'],
'41':['Jan Odegard','Crystall Phallus','2392', '101059'],
'43':['Karl Felix Krafft','Kragerø Bad Boyz','2378', '127081'],
'44':['Tobias Midtlyng','Fritidshobby AS','2376', '130299'],
'45':['Petter Andreassen','Mordor F.C.','2375', '133424'],
'46':['Alfred Askvig','Auba Libre','2368', '148164'],
'47':['Espen Strom','Motstrøm','2368', '148870'],
'48':['Espen Kloster','Kloster FC','2358', '172234'],
'49':['Jonas Øvestad','Lovalova','2339', '224791'],
'50':['Fredrik Løken','Freddy Rovers','2339', '225234'],
'51':['Tobias Nymo Melseth','Kokelv FK','2335', '236476'],
'52':['Kristoffer Eikrem','jævla ålesund','2332', '247058'],
'53':['Eivind Thane','Ji- Sung Parkthebus','2332', '247511'],
'54':['Eirik Haarr','Våland Vixens','2321', '282139'],
'55':['Eirik Nordengen','Northfields netters','2319', '290788'],
'56':['Jon Arne Gullholm','Gullholm fc','2303', '351685'],
'57':['Sondre Golden Midtgarden','Ayew Shaw?','2288', '415090'],
'58':['Håkon Tollefsen','Somehow I Manage','2280', '448780'],
'59':['Kim Are Kold Gabrielsen','Lysskar Nabolag','2274', '479869'],
'60':['Mikkel Hoff Jensen','FemaleBdyInspctr69rs','2258', '561730'],
'61':['Fabian von Harling','ThePeleOfMissionary','2233', '702001'],
'62':['Erik Wikstøl','Park the Ji-sung Bus','2218', '792918'],
'63':['Frederik Okland','Rumham United','2198', '927965'],
'64':['August Haug Bjerknæs','Sykeb IL','2170', '1122493']
}

export default data;