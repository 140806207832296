<template>
  
<div id="app">
 <b-form-select v-model="selected">
 <option v-for="option in options" v-bind:value="option" :key="option.value">
    {{option.text}}
  </option>
  </b-form-select>
    <div class="container">
        <div class = "card mt-5">
            <h2 class="card-header">{{selected.value}}</h2>
            <table class="table">
                <thead class="thead-dark">
                    <th>#</th>
                    <th>Manager</th>
                    <th>Team</th>  
                    <th>Points</th>
                    <th>FPL Rank</th>
            </thead>
            <tbody>
                <tr v-for="(standingData,rank) in getHistoricData(selected.value)" :key="rank"
                :style="[getRowStyle(rank, selected.type), {color: 'black'}]" style="color:black">
                    <td>{{rank}}</td>
                    <td>{{standingData[0]}}</td>
                    <td>{{standingData[1]}}</td>
                    <td>{{standingData[2]}}</td>
                    <td>{{standingData[3]}}</td>
                </tr>
            </tbody>
        </table>
        </div>
    </div>
</div>
</template>

<script>
import elite2023data from '../data/Elite2023.js'
import challenger2023data from '../data/Challenger2023.js'
import elite2022data from '../data/Elite2022.js'
import challenger2022data from '../data/Challenger2022.js'
import elite2021data from '../data/Elite2021.js'
import challenger2021data from '../data/Challenger2021.js'
import elite2020data from '../data/Elite2020.js'
import challenger2020data from '../data/Challenger2020.js'
import elite2019data from '../data/Elite2019.js'
import challenger2019data from '../data/Challenger2019.js'
import elite2018data from '../data/Elite2018.js'

export default {
        data: function() {
            return { selected : { text: 'Select league/season', value: '', type: ''},
                options: [
                { text: 'Select league/season', value: '', type: ''},
                { text: 'Elite64Nor Main league 2023/24', value: 'Elite2023', type: 'Elite'},
                { text: 'Elite64Nor Challenger league 2023/24', value: 'Challenger2023', type: 'Challenger'},
                { text: 'Elite64Nor Main league 2022/23', value: 'Elite2022', type: 'Elite'},
                { text: 'Elite64Nor Challenger league 2022/23', value: 'Challenger2022', type: 'Challenger'},
                { text: 'Elite64Nor Main league 2021/22', value: 'Elite2021', type: 'Elite'},
                { text: 'Elite64Nor Challenger league 2021/22', value: 'Challenger2021', type: 'Challenger'},
                { text: 'Elite64Nor Main league 2020/21', value: 'Elite2020', type: 'Elite'},
                { text: 'Elite64Nor Challenger league 2020/21', value: 'Challenger2020', type: 'Challenger'},
                { text: 'Elite64Nor Main league 2019/20', value: 'Elite2019', type: 'Elite'},
                { text: 'Elite64Nor Challenger league 2019/20', value: 'Challenger2019', type: 'Challenger'},
                { text: 'Elite64Nor Main league 2018/19', value: 'Elite2018', type: 'Elite'},
                ]
            }
        },
        methods: {
            getHistoricData: function(selection) {
                if (selection == 'Elite2023') {
                    return elite2023data;
                } else if (selection == 'Challenger2023') {
                    return challenger2023data;
                } else if (selection == 'Elite2022') {
                    return elite2022data;
                } else if (selection == 'Challenger2022') {
                    return challenger2022data;
                } else if (selection == 'Elite2021') {
                    return elite2021data;
                } else if (selection == 'Challenger2021') {
                    return challenger2021data;
                } else if (selection == 'Elite2020') {
                    return elite2020data;
                } else if (selection == 'Challenger2020') {
                    return challenger2020data;
                } else if (selection == 'Elite2019') {
                    return elite2019data;
                } else if (selection == 'Challenger2019') {
                    return challenger2019data;
                } else if (selection == 'Elite2018') {
                    return elite2018data;
                } else {
                    return '';
                }
            },
            getRowStyle: function (rank, type) {
            
                console.log('type is ' + type)
                if (rank == 1) {
                    return {
                        border: 'solid 2px gold'
                        };
                } else if (rank == 2) {
                    return {
                        border: 'solid 2px silver'
                        };
                } else if (rank == 3) {
                    return {
                        border: 'solid 2px #cd7f32'
                        };
                } else if (rank <= 14 && type == 'Challenger') {
                    return {
                        border: 'solid 2px green'
                        };
                } else if (rank > 50) {
                    return {
                        border: 'solid 2px red'
                        };
                }
                
            }
        }   
    };
</script>

<style>

</style>
