import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home.vue'
import FplLeagueInsights from '../components/FplLeagueInsights.vue'
import ManagerDbData from '../components/ManagerDbData.vue'
import Elite64Nor from '../components/Elite64Nor.vue'
import Elite64NorAbout from '../components/Elite64NorAbout.vue'
import Elite64NorHoF from '../components/Elite64NorHoF.vue'
import Elite64NorHistory from '../components/Elite64NorHistory.vue'
import Elite64NorInsights from '../components/Elite64NorInsights.vue'
import About from '../components/About.vue'
import Update from '../components/Update.vue'
import CountryRanking from '../components/CountryRanking.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/fplLeagueInsights',
    name: 'Fpl League Insights',
    component: FplLeagueInsights
  },
  {
    path: '/topCyborg',
    name: 'TopCyborgScore',
    component: ManagerDbData
  },
  {
    path: '/countryRanking/Norway',
    name: 'CountryRanking',
    component: CountryRanking
  },
  {
    path: '/elite64Nor',
    name: 'Elite64 Norway',
    component: Elite64Nor,
    children: [{
      path: 'elite64NorAbout',
      alias: '/elite64Nor',
      component: Elite64NorAbout
    },
    {
      path: 'elite64NorHoF',
      component: Elite64NorHoF
    },
    {
      path: 'elite64NorHistory',
      component: Elite64NorHistory
    },
    {
      path: 'elite64NorInsights',
      component: Elite64NorInsights
    }]
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/update',
    name: 'Update',
    component: Update
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
